<template>
  <a-layout-content class="container-fluid p-0 py-5">
    <a-layout-content class="container-lg d-flex flex-column ps-4 ps-md-5 cover align-items-center">
      <span>
        walletName: {{ ixd.wallet.name }}<br/>
        walletAddress: {{ ixd.wallet.address.view }}<br/>
        networkId: {{ ixd.wallet.networkId }}<br/>
        <br/>
        <div class="d-flex gap-5">
          <div class="col">
            {{ ixd.rocketPublicNFT.name.view }}<br/>
            {{ ixd.rocketPublicNFT.symbol.view }}<br/>
            {{ ixd.rocketPublicNFT.baseURI.view }}<br/>
            address: {{ ixd.rocketPublicNFT.address }}<br/>
            totalSupply {{ ixd.rocketPublicNFT.totalSupply.view }}<br/>
            maxSupply  {{ ixd.rocketPublicNFT.maxSupply.view }}<br/>
            walletBalanceOf  {{ ixd.rocketPublicNFT.walletBalanceOf.view }}<br/>
            holdTokenIds {{ ixd.rocketPublicNFT.holdTokenIds.handled }}<br/>
            <div>
              <button-busy :busying=ixd.rocketPublicNFT.state.busy  size="small" @click="onMintPublicNFT" type="secondary" class="d-flex align-items-center fw-bold">
                onMintPublicNFT
              </button-busy>
            </div>
          </div>
          <div class="col">
            {{ ixd.mintPasscard.name.view }}<br/>
            {{ ixd.mintPasscard.symbol.view }}<br/>
            {{ ixd.mintPasscard.baseURI.view }}<br/>
            address: {{ ixd.mintPasscard.address }}<br/>
            totalSupply {{ ixd.mintPasscard.totalSupply.view }}<br/>
            maxSupply  {{ ixd.mintPasscard.maxSupply.view }}<br/>
            walletBalanceOf  {{ ixd.mintPasscard.walletBalanceOf.view }}<br/>
            holdTokenIds {{ ixd.mintPasscard.holdTokenIds.handled }}<br/>
            <div>
              <a-button size="small" @click="onMintSpecialNFT" type="secondary" class="d-flex align-items-center fw-bold">
                onMintSpecialNFT
              </a-button>
              <a-button size="small" @click="builderSpecialSignCode" type="secondary" class="d-flex align-items-center fw-bold">
                builderSpecialSignCode
              </a-button>
              {{ specialSignCode }}
            </div>
          </div>
        </div>
      </span>

      <a-button size="small" @click="onRefresh" type="secondary" class="d-flex align-items-center fw-bold">
        onRefresh
      </a-button>

    </a-layout-content>
  </a-layout-content>


</template>

<script>
import axios from 'axios'
import { Layout, Button, Popover, Tabs, Input, message } from 'ant-design-vue'
import Busy from '../components/busy'
import ButtonBusy from '../components/button-busy'
import { parseAntComponent } from '../utils/helpers'
import { iArtAmazingRocketExample, iArtAstronaut, iOpensea } from '../components/icons'

export default {
  components: {
    ...parseAntComponent([Layout.Content, Button, Popover, Tabs, Tabs.TabPane, Input]),
    Busy,
    ButtonBusy,

    iArtAmazingRocketExample,
    iArtAstronaut,
    iOpensea
  },
  data() {
    return {
      currStep: null,
      specialSignCode: ''
    }
  },
  async mounted (e) {
    this.$store.nftSwaps.mintPasscard.specialSignCode = this.getQueryVariable('c') || ''
  },
  methods: {
     getQueryVariable(variable){
      let query=window.location.search.substring(1);
      let vars=query.split("&");
      for(let i=0,size=vars.length;i<size;i++){
        let param=vars[i];
        let index=param.indexOf("=");
        let pair=param.substring(0,index);
        if(pair==variable){
          return param.substring(index+1,param.length);
        }
      }
      return false;
    },
    async builderSpecialSignCode () {
      // await this.$store.nftSwaps.rocketPublicNFT.publicMint()

      // await this.$store.nftSwaps.rocketPublicNFT.aaa()
      // console.log(
      //   await this.$store.nftSwaps.rocketPublicNFT.contract.methods.tokensOfOwner('0xB1deb65a74231D2feF17faFB063d1Ef95Ac724fd').call()
      // )
      // await this.$store.nftSwaps.rocketPublicNFT.signTypedData()
      // await this.$store.nftSwaps.rocketPublicNFT.signWeb3Eth()

      this.specialSignCode = this.$store.nftSwaps.mintPasscard.builderSpecialSignCode()
    },

    async onRefresh () {
      await this.$store.nftSwaps.rocketPublicNFT.holdTokenIds.referrer()
      await this.$store.nftSwaps.mintPasscard.holdTokenIds.referrer()
    },

    async onMintPublicNFT() {
      await this.$store.nftSwaps.rocketPublicNFT.onMint()
    },
    async onMintSpecialNFT() {
      await this.$store.nftSwaps.mintPasscard.onMint()
    },
  },
  computed: {
    // 结构性
    structure() {

      return {
      }
    },

    ixd() {
      const { nftSwaps, wallet } = this.$store
      const cert = nftSwaps.mintPasscard.specialSignCode === ''

      // console.log(nftSwaps.mintPasscard.listenCode.address )
      const mint = {
        busy: nftSwaps.mintPasscard.state.busy,
        disabled: cert,
        // 已经铸造过
        minted: nftSwaps.mintPasscard.walletBalanceOf.handled > 0,
        text: cert
          ? 'Invitation Only'
          : 'Mint Now'
      }

      return {
        rocketPublicNFT: nftSwaps.rocketPublicNFT,
        mintPasscard: nftSwaps.mintPasscard,
        wallet,
        mint,
        aa: nftSwaps.mintPasscard.specialSignCode
      }
    }
  },
}
</script>

<style scoped lang="less">
.cover {
  .astronaut {
    position: absolute;
    right: 20%;
    /* top: 30%; */
    margin-top: -50px;
  }

  .h1 {
    font-size: 45px;
    line-height: 54px;
  }
  .ant-btn-secondary {
    background-color: #0E1014;
    color: #fff;
    border: 0;
    &:hover {
      background-color: #FFD12D;
      color: #0E1014;
    }
    &:disabled {
      background-color: #cfebfa;
      color: #0E1014;
    }
  }
}

@media (min-width: 2000px) {

}
@media (max-width: 992px) {

}
@media (max-width: 768px) {

}

@media (max-width: 540px) {
  .h1 {
    font-size: 40px;
    line-height: 48px;
  }
  .h2 {
    font-size: 26px;
    line-height: 30px;
  }
}
</style>
