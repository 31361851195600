/** @format */

import { reactive } from 'vue'
import axios from 'axios'
import Web3 from 'web3'
// import { MerkleTree } from 'merkletreejs'
import BN from 'bignumber.js'
import {
  ModelValueString,
  ModelValueDate,
  ModelValueEther,
  ModelNftSwap,
  ModelValueUnit256,
  ModelValueUint8,
  ModelValueArray,
  ModelValueWallet,
  ModelValueBool,
  ModelSwap,
} from '../../models'
import storeWallet from '../wallet'
import notify from '../notify'
import swaps from '../swaps'
import { getDotenvAddress, listenEvent } from '../helpers/methods'
// import keccak256 from 'keccak256'
import { mintPasscardAbi, exExRefundAbi } from './abi'
// import { WHITELIST_ADDRESSES, WHITELIST_ADDRESSES_CHECK } from './wladdress'

async function asyncMap(array, mapper) {
  return await Promise.all(array.map(mapper))
}

const WHITELIST_ADDRESSES = []
const WHITELIST_ADDRESSES_CHECK = []

// const WHITELIST_ADDRESSES = [
//   '0xD52696cE351c92c35410C68d2FBaDD45cdaB8D57',
//   '0xf66f033908d8911c76d46Be1ae7F4645D062c969',
//   '0x7013F526e93812dDC98AE4814AE33994E32Ad926',
//   '0xb48C67979f53cF60f60Ea6336a9600c27B7a866f',
//   '0xa44d63554c28F1e40e45500f95fccCF5EE79187b',
//   '0xcBd2026A0faF31Aa9D2E43eC861c085D74f790C0',
//   '0x712ce700d08b6158392Dc0ba91c0819C2fef9a39',
//   '0x2D94DD48AfbDA1aa46eA1Aa9360e911BC29F2273',
//   '0x31C00012BA64F62e44E8F4941E22105b65037BAa',
//   '0xB1deb65a74231D2feF17faFB063d1Ef95Ac724fd',
//   '0xb9555C4FB86B5072219C9661bA3738Eb28631cA2',
//   '0x30CD135A55679dEC1B26767C9266f950Eb91BC3c',
// ]

// const leaves = WHITELIST_ADDRESSES.map(addr => keccak256(addr))
// let merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true })
// const proof = merkleTree.getProof(leaves)
// const root = merkleTree.getRoot()

// console.log(merkleTree.verify(proof, leaves, root)) // true

// console.log(root)

const refundExwhiteListCheck = async address => {
  const { data } = await axios.get(`${process.env.VUE_APP_REFUND_EX_WHITELIST_CHECK_URL}?addr=${address}`)
  let result = []

  if (data.code && data.code === '0' && data.data.length > 0) {
    result = data.data
  }

  return result
}

const extendFunc = __root__ => {
  __root__.signTypedData = async () => {
    var msg1 = '0xaa9e800BB7bE0260e3a6eBeb9842b71923bf5A2f'
    var num1 = 1
    var msgParams = [
      {
        type: 'address',
        name: 'Message',
        value: msg1,
      },
      {
        type: 'uint256',
        name: 'num',
        value: num1,
      },
    ]
    var from = '0xaa9e800BB7bE0260e3a6eBeb9842b71923bf5A2f'
    var params = [msgParams, from]
    var method = 'eth_signTypedData'
    var instance = this
    storeWallet.web3.currentProvider.sendAsync(
      {
        method,
        params,
        from,
      },
      function (err, result) {
        if (err) return console.dir(err)
        if (result.error) {
          alert(result.error.message)
        }
        let sign = result.result
        // instance.contract.verifyAddressFromTypedSign(sign, msg1, num1, from, function(err1, res1) {
        //     console.log("res1 from verifyAddressFromTypedSign: " + res1)
        //     // if the flow reaches here, do the task that needs to be done with the params passed.
        // })
        // instance.contract.verifyAddressFromTypedSignWithEvent.sendTransaction(sign, msg1, num1, from, {from:from}, function(err1, res1) {
        //     console.log("res1 from verifyAddressFromTypedSignWithEvent: " + res1)
        //     // res1 should return the transaction hash and you will receive an event(check in console logs) which will contain your metamask address.
        //     // if the flow reaches here, do the task that needs to be done with the params passed.
        // })
      },
    )
  }
  __root__.signWeb3Eth = async () => {
    var message = storeWallet.web3.utils.sha3('Hello World')
    var from = storeWallet.address.handled
    var instance = this
    storeWallet.web3.eth.sign(from, message, function (e, res) {
      // the following is by passing the complete siignature into the verification smart contract.
      instance.contract.verifyAddressFromWeb3Sign(res, message, from, function (err1, res1) {
        // console.log("res1 from verifyAddressFromWeb3Sign: " + res1)
      })
      var signature = res.substr(2) //remove 0x
      const r = '0x' + signature.slice(0, 64)
      const s = '0x' + signature.slice(64, 128)
      const v = '0x' + signature.slice(128, 130)
      const v_decimal = storeWallet.web3.toDecimal(v)
      // the following is by passing v, r and s separately into the verification smart contract.
      instance.contract.verifyAddressWeb3SignElements(message, v_decimal, r, s, from, function (err1, res1) {
        // console.log("res1 from verifyAddressWeb3SignElements: " + res1)
      })
    })
  }
}

const result = reactive({
  mintPasscard: ModelNftSwap.create({
    code: 'MINT_PASSCARD',
    dotenvAddressName: 'MINT_PASSCARD',
    abi: mintPasscardAbi,
    baseURIMethodName: '_baseTokenURI',
    maxSupplyMethodName: 'collectionSize',
  }).extend(function (__root__) {
    const { address, contract } = __root__

    // mint 单次的量控制
    this.ONCE_MINT_MIN_VOLUME = 1
    // 白名单地址最大mint 数
    this.__WHITELIST_MAX_MINT_VOLUME__ = 2
    this.WHITELIST_MAX_MINT_VOLUME = this.__WHITELIST_MAX_MINT_VOLUME__
    // Public 地址最大mint 数
    this.__PUBLIC_MAX_MINT_VOLUME__ = 30
    this.PUBLIC_MAX_MINT_VOLUME = this.__PUBLIC_MAX_MINT_VOLUME__
    this.DEFAULT_MINT_VOLUME = 2

    // tab 索引数
    this.tabTimelineIdx = 1

    // 白名单可mint量
    this.whiteListMintTotalSupply = ModelValueString.create()
    // 白名单已mint量
    this.whiteListMintedVolume = ModelValueString.create()
    // 白名单mint价格
    this.whiteListMintPrice = ModelValueEther.create({
      viewDecimal: 2,
      decimals: ModelValueUint8.create().setValue(18),
    })
    // mint 开始时间
    this.whiteListMintBegin = ModelValueDate.create()

    this.WHITELIST_ADDRESSES = WHITELIST_ADDRESSES
    this.searchWhiteListAddress = async address => {
      const { WHITELIST_ADDRESSES } = this
      let result = false

      try {
        address = Web3.utils.toChecksumAddress(address)

        result = WHITELIST_ADDRESSES_CHECK.includes(address)
      } catch (e) {
        console.error(e)
      }

      return result
    }

    this.etherscanUrl = `https://etherscan.io/token/${address}`
    this.openseaUrl = 'https://opensea.io/collection/twitterscan'
    this.elementUrl = 'https://element.market/collections/twitterscan'
    this.x2y2Url = 'https://x2y2.io/collection/twitterscan/items'
    this.okxUrl = 'https://www.okx.com/web3/nft/primary/details/10079'

    this.getConf = async () => {
      const { address, contract } = this
      const { holdTokenIds } = this
      const { wlSize, wlCount, wlPrice, begin, publicBegin, publicPrice } = await contract.methods.conf().call()

      this.whiteListMintTotalSupply.value = wlSize
      this.whiteListMintedVolume.value = wlCount
      this.whiteListMintPrice.ether = wlPrice
      this.whiteListMintBegin.value = begin
      // Public
      // XXX: 临时
      // this.publicMintMintPrice.value = publicPrice
      this.publicMintMintPrice.value = 150000000000000000
      this.publicMintMintBegin.value = publicBegin
    }

    // 当前钱包已 whitelist mint 的量
    this.walletMintedVolume = ModelValueUnit256.create({
      async referrer() {
        const { contract } = __root__
        const walletAddress = storeWallet.address.handled

        this.state.busy = true
        // 查询地址已 mint 的量
        try {
          let lock = false
          // console.log('------')
          // setTimeout(() => {
          //   if (!lock) {
          //     this.value = 0
          //     this.state.busy = false
          //   }
          // }, 3000)
          let minted = await contract.methods.whitelistClaimNum(walletAddress).call()
          // console.log('------ end')

          lock = true
          this.value = +minted || 0
        } catch (e) {
          console.log(e)
        }
        this.state.busy = false
      },
    })

    this.mintVolume = ModelValueUint8.create().setValue(this.DEFAULT_MINT_VOLUME)

    this.onDecline = () => {
      if (this.mintVolume.value > 0) {
        this.mintVolume.value = Math.max(this.mintVolume.value - 1, this.ONCE_MINT_MIN_VOLUME)
      }
    }
    this.onIncrease = () => {
      const {
        WHITELIST_MAX_MINT_VOLUME,
        PUBLIC_MAX_MINT_VOLUME,
        DEFAULT_MINT_VOLUME,
        whiteListMintTotalSupply,
        whiteListMintedVolume,
        maxSupply,
        totalSupply,
      } = this
      const val =
        this.tabTimelineIdx === 0 ||
        (this.tabTimelineIdx === 1 && this.isWhiteListAddress.value && +this.walletMintedVolume.value < 2)
          ? this.walletMintedVolume.value
          : this.walletPublicMintedVolume.value
      const max =
        this.tabTimelineIdx === 0 ||
        (this.tabTimelineIdx === 1 && this.isWhiteListAddress.value && +this.walletMintedVolume.value < 2)
          ? Math.min(WHITELIST_MAX_MINT_VOLUME - val, +this.maxSupply.value - +this.totalSupply.value)
          : Math.min(
              PUBLIC_MAX_MINT_VOLUME - val,
              +this.maxSupply.value - +this.totalSupply.value,
              // +maxSupply.value - +whiteListMintTotalSupply.value - (+totalSupply.value - +whiteListMintedVolume.value),
            )

      if (this.mintVolume.value < max) {
        this.mintVolume.value = Math.max(0, Math.min(this.mintVolume.value + 1, max))
      } else {
        this.mintVolume.value = max
      }
    }

    // 当前钱包地址是否是白名单内的
    this.isWhiteListAddress = {
      value: false,
    }
    this.syncIsWhiteListAddress = async (address = storeWallet.address.handled) => {
      let arr = await this.whiteListCheck(address)
      let result = false

      if (arr.length > 0) {
        result = true
      }
      // const result = await this.searchWhiteListAddress(address)

      // sync
      this.isWhiteListAddress.value = result
      return result
    }

    this.whiteListCheck = async address => {
      const { data } = await axios.get(`${process.env.VUE_APP_WHITELIST_CHECK_URL}?addr=${address}`)
      let result = []

      if (data.code && data.code === '0' && data.data.length > 0) {
        result = data.data
      }

      return result
    }

    // 钱包地址对应的数据更新
    this.onRefresh = async () => {
      await this.syncIsWhiteListAddress()
      await this.walletMintedVolume.referrer()

      const {
        address,
        WHITELIST_MAX_MINT_VOLUME,
        PUBLIC_MAX_MINT_VOLUME,
        DEFAULT_MINT_VOLUME,
        whiteListMintTotalSupply,
        whiteListMintedVolume,
        maxSupply,
        totalSupply,
      } = this
      const walletAddress = storeWallet.address.handled

      if (
        this.tabTimelineIdx === 0 ||
        (this.tabTimelineIdx === 1 && this.isWhiteListAddress.value && +this.walletMintedVolume.value < 2)
      ) {
        const max = Math.min(
          WHITELIST_MAX_MINT_VOLUME - +this.walletMintedVolume.value,
          // +whiteListMintTotalSupply.value - +whiteListMintedVolume.value,
          +this.maxSupply.value === 0 ? DEFAULT_MINT_VOLUME : +this.maxSupply.value - +this.totalSupply.value,
        )

        // 自动设置为缺省 mint 数
        this.mintVolume.value = Math.max(0, Math.min(DEFAULT_MINT_VOLUME, max))
      } else {
        const max = Math.min(
          PUBLIC_MAX_MINT_VOLUME - +this.walletPublicMintedVolume.value,
          +this.maxSupply.value === 0 ? DEFAULT_MINT_VOLUME : +this.maxSupply.value - +this.totalSupply.value,
          // +maxSupply.value - +whiteListMintTotalSupply.value - (+totalSupply.value - +whiteListMintedVolume.value),
        )

        // Public
        await this.walletPublicMintedVolume.referrer()
        // 自动设置为缺省 mint 数
        this.mintVolume.value = Math.max(0, Math.min(DEFAULT_MINT_VOLUME, max))
      }
    }

    // Public
    this.publicMintMintPrice = ModelValueEther.create({
      viewDecimal: 2,
      decimals: ModelValueUint8.create().setValue(18),
    })
    this.publicMintMintBegin = ModelValueDate.create()
    // 当前钱包已 public mint 的量
    this.walletPublicMintedVolume = ModelValueUnit256.create({
      async referrer() {
        const { contract } = __root__
        const walletAddress = storeWallet.address.handled

        this.state.busy = true
        // 查询地址已 mint 的量
        try {
          let lock = false
          // console.log('------')
          setTimeout(() => {
            if (!lock) {
              this.value = 0
              this.state.busy = false
            }
          }, 3000)
          let minted = await contract.methods.publicClaimNum(walletAddress).call()
          // console.log('------ end')

          lock = true
          this.value = +minted || 0
        } catch (e) {
          console.log(e)
        }
        this.state.busy = false
      },
    })

    /**
     * 铸造 NFT
     */
    this.onMint = async () => {
      let price = 0
      let checkcode = []
      const { contract, address, state } = this
      const walletAddress = storeWallet.address.handled

      // 限制当前提交待确认的交易只有一份
      state.beforeUpdate()

      // 只针对 whitelist
      if (
        this.tabTimelineIdx === 0 ||
        (this.tabTimelineIdx === 1 && this.isWhiteListAddress.value && +this.walletMintedVolume.value < 2)
      ) {
        price = this.whiteListMintPrice.ether
        checkcode = await this.whiteListCheck(walletAddress)
        // checkcode = merkleTree.getHexProof(keccak256(walletAddress))

        // 提交前再次测试不在范围内
        if (!(checkcode.length > 0)) {
          state.afterUpdate()

          return false
        }
        // Public
      } else if (this.tabTimelineIdx === 1) {
        price = this.publicMintMintPrice.ether
      } else {
        state.afterUpdate()

        return false
      }

      const { update, dismiss } = notify.notification({ message: `Mint ${this.name.view}` })
      const sendOpts = {
        from: walletAddress,
        value: new BN(price).times(this.mintVolume.value),
      }

      const _method =
        this.tabTimelineIdx === 0 ||
        (this.tabTimelineIdx === 1 && this.isWhiteListAddress.value && +this.walletMintedVolume.value < 2)
          ? await contract.methods.whitelistMint(this.mintVolume.value, checkcode)
          : await contract.methods.publicMint(this.mintVolume.value)

      // NOTE: Gas
      try {
        sendOpts.gas = await _method.estimateGas({
          from: walletAddress,
        })
      } catch (err) {
        sendOpts.gas = 510000
        console.error(err)
      }

      // sendOpts.gas *= 1.2

      return _method
        .send(sendOpts)
        .once('transactionHash', transactionHash => {
          notify.handler(transactionHash)

          listenEvent({
            // name: 'whitelistMint',
            contract,
            transactionHash,
          })
            .then(data => {
              const { returnValues } = data
              const filter = returnValues.from === walletAddress

              if (!filter) return false

              dismiss() // 销毁
              state.afterUpdate()

              // sync
              this.getConf()
              this.onRefresh()
            })
            .catch(err => {
              dismiss() // 销毁
              state.afterUpdate()
            })
        })
        .catch(err => {
          console.error(err)
          dismiss()

          notify.updateError({
            update,
            code: err.code,
            message: err.message,
          })

          state.afterUpdate()
        })
    }

    this.holdTokenInfo = []
    this.tempHoldTokenInfo = []

    this.findRefundBusy = false
    this.findRefundFinish = false
    this.refundExNftIdList = []
    this.refundPublicNftIdList = []

    this.isRefundExWhiteListAddress = false
    //
    this.REFUND_EXA_SCOPE_MIN = +process.env.VUE_APP_REFUND_EXA_SCOPE_MIN
    this.REFUND_EXA_SCOPE_MAX = +process.env.VUE_APP_REFUND_EXA_SCOPE_MAX
    this.REFUND_EXB_SCOPE_MIN = +process.env.VUE_APP_REFUND_EXB_SCOPE_MIN
    this.REFUND_EXB_SCOPE_MAX = +process.env.VUE_APP_REFUND_EXB_SCOPE_MAX
    this.REFUND_PUBLIC_SCOPE_MIN = +process.env.VUE_APP_REFUND_PUBLIC_SCOPE_MIN

    //
    this.findRefund = async () => {
      const {
        REFUND_EXA_SCOPE_MIN,
        REFUND_EXA_SCOPE_MAX,
        REFUND_EXB_SCOPE_MIN,
        REFUND_EXB_SCOPE_MAX,
        REFUND_PUBLIC_SCOPE_MIN,
        name,
      } = this
      const walletAddress = storeWallet.address.handled
      const { address } = this

      // 重置
      this.holdTokenInfo = []
      this.tempHoldTokenInfo = []
      this.refundExNftIdList = []
      this.refundPublicNftIdList = []

      this.findRefundBusy = true
      this.findRefundFinish = false

      // 获取最新的 walletBalanceOf
      await swaps.multicall.batcher([
        {
          call: [address, contract.methods.balanceOf(walletAddress).encodeABI()],
          target: this.walletBalanceOf,
        },
      ])

      const { walletBalanceOf } = this
      // console.log('walletBalanceOf', +walletBalanceOf.value)
      // walletBalanceOf 没有的跳过流程
      if (!(+walletBalanceOf.value > 0)) {
        this.findRefundBusy = false

        return false
      }

      const holdTokenInfo = Array(+walletBalanceOf.value)
        .fill('')
        .map(item => ModelValueUnit256.create({ referrer() {} }))

      // Ex 白名单检查
      // let checkcode = await refundExwhiteListCheck(walletAddress)
      // this.isRefundExWhiteListAddress = checkcode.length > 0

      await asyncMap(holdTokenInfo, async (item, idx) => {
        let data = await contract.methods.tokenOfOwnerByIndex(walletAddress, idx).call()
        let ok = await contract.methods.canBeRefunded(walletAddress, data).call()
        let val = +data

        this.tempHoldTokenInfo.push(data)
        // console.log(
        //   ok,
        //   data,
        //   REFUND_EXA_SCOPE_MIN <= val && REFUND_EXA_SCOPE_MAX >= val,
        //   REFUND_EXB_SCOPE_MIN <= val && REFUND_EXB_SCOPE_MAX >= val,
        //   (REFUND_EXA_SCOPE_MIN <= val && REFUND_EXA_SCOPE_MAX >= val) ||
        //     (REFUND_EXB_SCOPE_MIN <= val && REFUND_EXB_SCOPE_MAX >= val),
        // )

        holdTokenInfo[idx].value = data
        if (
          (REFUND_EXA_SCOPE_MIN <= val && REFUND_EXA_SCOPE_MAX >= val) ||
          (REFUND_EXB_SCOPE_MIN <= val && REFUND_EXB_SCOPE_MAX >= val)
        ) {
          // 过滤，必须白名单内
          // if (this.isRefundExWhiteListAddress) {
          this.refundExNftIdList.push({
            label: `${name.view} #${val}`,
            value: val + '',
          })
          // }
        } else if (REFUND_PUBLIC_SCOPE_MIN <= val) {
          if (ok === true || ok === 'true') {
            this.refundPublicNftIdList.push({
              label: `TwitterScan Pass #${val}`,
              value: val + '',
            })
          }
        }
      })

      // await swaps.multicall.batcher(
      //   this.holdTokenInfo.map((item, idx) => ({
      //     call: [address, contract.methods.tokenOfOwnerByIndex(walletAddress, idx).encodeABI()],
      //     target: this.holdTokenInfo[idx],
      //   })),
      // )

      // 分类
      // holdTokenInfo.forEach(item => {
      //   let val = +item.value
      //   console.log(
      //     'holdTokenInfo',
      //     val,
      //     item.value,
      //     REFUND_EXA_SCOPE_MIN <= val && REFUND_EXA_SCOPE_MAX >= val,
      //     REFUND_EXB_SCOPE_MIN <= val && REFUND_EXB_SCOPE_MAX >= val,
      //     REFUND_PUBLIC_SCOPE_MIN <= val,
      //   )

      //   if (
      //     (REFUND_EXA_SCOPE_MIN <= val && REFUND_EXA_SCOPE_MAX >= val) ||
      //     (REFUND_EXB_SCOPE_MIN <= val && REFUND_EXB_SCOPE_MAX >= val)
      //   ) {
      //     // 过滤，必须白名单内
      //     if (this.isRefundExWhiteListAddress) {
      //       this.refundExNftIdList.push({
      //         label: `${name.view} #${val}`,
      //         value: val + '',
      //       })
      //     }
      //   } else if (REFUND_PUBLIC_SCOPE_MIN <= val) {
      //     this.refundPublicNftIdList.push({
      //       label: `TwitterScan Pass #${val}`,
      //       value: val + '',
      //     })

      //     console.log(this.refundPublicNftIdList.length)
      //   }
      // })

      this.holdTokenInfo = holdTokenInfo

      this.findRefundBusy = false
      this.findRefundFinish = true
    }

    this.selectPublicRefundId = ''
    // Public onRefund
    this.onRefund = async () => {
      const { contract, address, state } = this
      const walletAddress = storeWallet.address.handled
      // console.log(!this.selectPublicRefundId)
      // 跳过没选中的
      if (!this.selectPublicRefundId) {
        return false
      }

      // 限制当前提交待确认的交易只有一份
      state.beforeUpdate()

      const { update, dismiss } = notify.notification({ message: `Refund ${this.name.view}` })
      const sendOpts = {
        from: walletAddress,
      }
      // console.log('refund', [this.selectPublicRefundId])
      const _method = await contract.methods.refund([this.selectPublicRefundId])

      // NOTE: Gas
      try {
        sendOpts.gas = await _method.estimateGas({
          from: walletAddress,
        })
      } catch (err) {
        sendOpts.gas = 510000
        console.error(err)
      }

      // sendOpts.gas *= 1.2

      return _method
        .send(sendOpts)
        .once('transactionHash', transactionHash => {
          notify.handler(transactionHash)

          listenEvent({
            contract,
            transactionHash,
          })
            .then(data => {
              const { returnValues } = data
              const filter = returnValues.from === walletAddress

              if (!filter) return false

              dismiss() // 销毁
              state.afterUpdate()

              // sync
              this.selectPublicRefundId = ''
              this.findRefund()
            })
            .catch(err => {
              dismiss() // 销毁
              state.afterUpdate()
            })
        })
        .catch(err => {
          console.error(err)
          dismiss()

          notify.updateError({
            update,
            code: err.code,
            message: err.message,
          })

          state.afterUpdate()
        })
    }

    this.onApprove = async (toAddress, tokenId, callback = async () => {}) => {
      const { contract, address, state } = this
      const walletAddress = storeWallet.address.handled

      // 限制当前提交待确认的交易只有一份
      state.beforeUpdate()

      // getApproved
      const approvedAddress = await contract.methods.getApproved(tokenId).call()

      if (approvedAddress === toAddress) {
        callback && callback()

        state.afterUpdate()
        return false
      }

      const { update, dismiss } = notify.notification({ message: `Approve #${tokenId}` })

      return await contract.methods
        .approve(toAddress, tokenId)
        .send({
          from: walletAddress,
        })
        .once('transactionHash', transactionHash => {
          notify.handler(transactionHash)

          listenEvent({
            contract,
            transactionHash,
          })
            .then(data => {
              const { returnValues } = data
              const filter = returnValues.from === walletAddress

              if (!filter) return false

              dismiss() // 销毁
              state.afterUpdate()

              // sync
              callback && callback()
            })
            .catch(err => {
              dismiss() // 销毁
              state.afterUpdate()
            })
        })
        .catch(err => {
          console.error(err)
          dismiss()

          notify.updateError({
            update,
            code: err.code,
            message: err.message,
          })

          state.afterUpdate()
        })
    }
  }),
})

result.exExRefund = ModelSwap.create({
  code: 'REFUND_TSP',
  dotenvAddressName: 'REFUND_TSP',
  abi: exExRefundAbi,
}).extend(function (__root__) {
  const { address, contract } = __root__

  this.selectExRefundId = ''

  this.onLuan = async () => {
    const { contract, address, state, selectExRefundId } = this

    result.mintPasscard.onApprove(address, selectExRefundId, async () => {
      this.onRefund()
    })
  }

  this.onRefundErr = ''

  this.onRefund = async () => {
    const walletAddress = storeWallet.address.handled
    const { address, state, selectExRefundId } = this

    state.beforeUpdate()

    // let checkcode = await refundExwhiteListCheck(walletAddress)
    const contract = new storeWallet.web3.eth.Contract(exExRefundAbi, address)

    // if (!(checkcode.length > 0) || !selectExRefundId) {
    //   state.afterUpdate()
    //   this.onRefundErr = `len ${checkcode.length}, tokenid ${selectExRefundId}`

    //   return false
    // }
    const { update, dismiss } = notify.notification({ message: `Refund Twitterscan Pass` })

    // const _method = await contract.methods.whitelistRefund([selectExRefundId], checkcode)
    const _method = await contract.methods.refund([selectExRefundId])

    const sendOpts = {
      from: walletAddress,
    }

    try {
      sendOpts.gas = await _method.estimateGas({
        from: walletAddress,
      })
    } catch (err) {
      sendOpts.gas = 610000
      console.error(err)
    }

    return _method
      .send(sendOpts)
      .once('transactionHash', transactionHash => {
        notify.handler(transactionHash)

        listenEvent({
          // name: '',
          contract,
          transactionHash,
        })
          .then(data => {
            const { returnValues } = data
            const filter = returnValues.from === walletAddress
            console.log(returnValues)
            if (!filter) return false

            dismiss() // 销毁
            state.afterUpdate()

            // sync
            this.selectExRefundId = ''
            result.mintPasscard.findRefund()
          })
          .catch(err => {
            dismiss() // 销毁
            state.afterUpdate()
          })
      })
      .catch(err => {
        console.error(err)
        dismiss()

        notify.updateError({
          update,
          code: err.code,
          message: err.message,
        })

        state.afterUpdate()
      })
  }

  // this.onRefund = async () => {
  //   const walletAddress = storeWallet.address.handled
  //   const { contract, address, state, selectExRefundId } = this
  //   // console.log('-----')
  //   let checkcode = await refundExwhiteListCheck(walletAddress)

  //   // 限制当前提交待确认的交易只有一份
  //   state.beforeUpdate()

  //   // 只针对 whitelist
  //   // 提交前再次测试不在范围内
  //   if (!(checkcode.length > 0) || !selectExRefundId) {
  //     state.afterUpdate()

  //     return false
  //   }

  //   const { update, dismiss } = notify.notification({ message: `Refund Twitterscan Pass` })
  //   const sendOpts = {
  //     from: walletAddress,
  //   }
  //   // console.log('whitelistRefund', [selectExRefundId], checkcode)
  //   // const _method = await contract.methods.whitelistRefund([selectExRefundId], checkcode)

  //   const _method = await contract.methods.whitelistRefund([selectExRefundId], checkcode)

  //   try {
  //     sendOpts.gas = await _method.estimateGas({
  //       from: walletAddress,
  //     })
  //   } catch (err) {
  //     sendOpts.gas = 510000
  //     console.error(err)
  //   }

  //   // sendOpts.gas *= 1.2

  //   return _method
  //     .send(sendOpts)
  //     .once('transactionHash', transactionHash => {
  //       notify.handler(transactionHash)

  //       listenEvent({
  //         // name: '',
  //         contract,
  //         transactionHash,
  //       })
  //         .then(data => {
  //           const { returnValues } = data
  //           const filter = returnValues.from === walletAddress

  //           if (!filter) return false

  //           dismiss() // 销毁
  //           state.afterUpdate()

  //           // sync
  //           this.selectExRefundId = ''
  //           result.mintPasscard.findRefund()
  //         })
  //         .catch(err => {
  //           dismiss() // 销毁
  //           state.afterUpdate()
  //         })
  //     })
  //     .catch(err => {
  //       console.error(err)
  //       dismiss()

  //       notify.updateError({
  //         update,
  //         code: err.code,
  //         message: err.message,
  //       })

  //       state.afterUpdate()
  //     })
  // }
})

export default result
