/** @format */

import { createRouter, createWebHistory } from 'vue-router'
import globalMessage from '../utils/global/message'
import { isDevelopmentMode } from '../utils/helpers'

import Home from '@/views/Home'
import HomeV2 from '@/views/HomeV2'
import NFT from '@/views/NFT'
import SpecialNFT from '@/views/SpecialNFT'
import PublicNFT from '@/views/PublicNFT'
import TestNFT from '@/views/TestNFT'
import MintPass from '@/views/MintPass'

import RootDefault from '@/components/layout/RootDefault'
import RootSpace from '@/components/layout/RootSpace'

const routes = [
  {
    path: '/',
    name: 'RootSpace',
    component: RootSpace,
    children: [
      {
        path: '',
        name: 'Home',
        component: HomeV2,
        // component: MintPass,
      },
      {
        path: 'nft',
        name: 'NFT',
        component: NFT,
      },
      // {
      //   path: '/about',
      //   name: 'MintPass',
      //   // component: MintPass,
      //   component: Home,
      // },
      // {
      //   path: 'diamondhand100',
      //   name: 'SpecialNFT',
      //   component: SpecialNFT,
      // },
      // {
      //   path: 'publicnft',
      //   name: 'PublicNFT',
      //   component: PublicNFT,
      // },
      // {
      //   path: 'testnft',
      //   name: 'TestNFT',
      //   component: TestNFT,
      // },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const suffix = to.meta.suffix || ''

  if (to.matched && to.matched.length) {
    document.title = (to.meta.title || process.env.VUE_APP_DEFAULT_TITLE) + suffix

    next()
  } else {
    // 404
    next({ path: '/' })
    globalMessage.error('404 NOT FOUND')
  }
})

export default router
