<template>
  <router-view class="col" />
</template>

<script>

export default {
  components: {
  },
}
</script>

<style lang="less" scoped>

</style>