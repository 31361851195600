<template>
  <a-layout-content class="container-fluid p-0 d-flex lib">
    <a-layout-content class="p-0 libox">
      <a href="https://metascan.pro" class="logo d-none d-md-flex"><iLogoAll /></a>
      <ul class="h-100 d-none d-md-flex">
        <li class="ball_metascan">
          <div class="bubble-container" @click="onModal('metascan')">
            <img src="../assets/base/lib/ball_metascan.png" />
          </div>
          <div>
            <div class="m-fw-700">MetaScan Lab</div>
            <span class="m-fw-600">Infrastructure Middleware</span>
          </div>
          <div class="bubble-shadow"></div>
        </li>
        <li class="ball_nns">
          <div class="bubble-container" @click="onModal('nns')">
            <img src="../assets/base/lib/ball_nns.png" />
          </div>
          <div>
            <div class="m-fw-700">NNS</div>
            <span class="m-fw-600">The cross-chain .META domain name</span>
          </div>
        </li>
        <!-- <li class="ball_mojor">
          <div class="bubble-container" @click="onModal('mojor')">
            <img src="../assets/base/lib/ball_mojor.png" />
          </div>
          <div>
            <div class="m-fw-700">MOJOR</div>
            <span class="m-fw-600">Build a Web3 native community</span>
          </div>
        </li> -->
        <!-- <li class="ball_ethgame">
          <div class="bubble-container" @click="onModal('ethgame')">
            <img src="../assets/base/lib/ball_ethgame.png" />
          </div>
          <div>
            <div class="m-fw-700">ETH.GAME</div>
            <span class="m-fw-600">The bridge connecting Web3 game</span>
          </div>
        </li> -->
        <!-- <li class="ball_register3">
          <div class="bubble-container" @click="onModal('register3')">
            <img src="../assets/base/lib/ball_register3.png" />
          </div>
          <div>
            <div class="m-fw-700">Registry3</div>
            <span class="m-fw-600">DAG based layerzero blockchain and DAO for web3 domain name allocation</span>
          </div>
        </li> -->
        <li class="ball_twitterscan">
          <div class="bubble-container" @click="onModal('twitterscan')">
            <img src="../assets/base/lib/ball_twitterscan.png" />
          </div>
          <div>
            <div class="m-fw-700">TwitterScan</div>
            <span class="m-fw-600">The No.1 twitter crypto sentiment analytic platform</span>
          </div>
        </li>
        <!-- <li class="non ball_whale">
          <div class="bubble-container">
            <img src="../assets/base/lib/icon_whale.png" />
          </div>
        </li> -->
        <li class="ball_metascan_shadow">
          <div class="bubble-shadow"></div>
        </li>
        <!-- <li class="ball_mojor_shadow">
          <div class="bubble-shadow"></div>
        </li> -->
        <li class="ball_twitterscan_shadow">
          <div class="bubble-shadow"></div>
        </li>
        <li class="ball_ethgame_shadow">
          <div class="bubble-shadow"></div>
        </li>
        <li class="ball_register3_shadow">
          <div class="bubble-shadow"></div>
        </li>
        <!-- <li class="ball_whale_shadow">
          <div class="bubble-shadow"></div>
        </li> -->
      </ul>

      <div class="mobile d-flex d-md-none flex-column">
        <ol class="d-flex flex-column">
          <li class="order-12 d-flex flex-column align-items-center">
            <div class="col-5" @click="onModal('metascan')">
              <img class="drop-metascan" src="../assets/base/lib/ball_metascan.png" />
            </div>
            <div class="col-12 d-flex flex-column align-items-center">
              <span class="m-fw-700">MetaScan Lab</span>
              <p class="m-fw-600 text-align-center" style="padding-left: 10%;">Infrastructure Middleware</p>
            </div>
          </li>
          <li class="order-11">
            <div class="d-flex flex-column col-7 me-3">
              <span class="m-fw-700">NNS</span>
              <p class="m-fw-600">The cross-chain .META domain name</p>
            </div>
            <div class="col-4" @click="onModal('nns')"><img class="drop-nns" src="../assets/base/lib/ball_nns.png" /></div>
          </li>
          <!-- <li class="order-10">
            <div class="d-flex flex-column col-7 me-3">
              <span class="m-fw-700">MOJOR</span>
              <p class="m-fw-600">Build a Web3 native community</p>
            </div>
            <div class="col-4" @click="onModal('mojor')"><img class="drop-mojor" src="../assets/base/lib/ball_mojor.png" /></div>
          </li> -->
          <!-- <li class="order-9">
            <div class="d-flex flex-column col-7 me-3">
              <span class="m-fw-700">ETH.GAME</span>
              <p class="m-fw-600">The bridge connecting Web3 game</p>
            </div>
            <div class="col-4" @click="onModal('ethgame')"><img class="drop-ethgame" src="../assets/base/lib/ball_ethgame.png" /></div>
          </li> -->
          <!-- <li class="order-8">
            <div class="d-flex flex-column col-7 me-3">
              <span class="m-fw-700">Registry3</span>
              <p class="m-fw-600">DAO Based Layerzero blockchain and DAO for web3 domain name allocation</p>
            </div>
            <div class="col-4" @click="onModal('register3')"><img class="drop-register3" src="../assets/base/lib/ball_register3.png" /></div>
          </li> -->
          <li class="order-7">
            <div class="d-flex flex-column col-7 me-3">
              <span class="m-fw-700">TwitterScan</span>
              <p class="m-fw-600">The No.1 twitter crypto sentiment analytic platform</p>
            </div>
            <div class="col-4" @click="onModal('twitterscan')"><img class="drop-twitterscan" src="../assets/base/lib/ball_twitterscan.png" /></div>
          </li>
        </ol>
        <div class="copyright d-flex flex-column flex-md-row">
          <span class="order-1 order-md-12 my-3 my-md-0">© 2023 Metascan Lab</span>
          <a href="mailto:team@metascan.pro" class="order-12 order-md-1"><em><img src="../assets/base/lib/mail.png" /></em>team@metascan.pro</a>
        </div>
      </div>

      <div class="copyright d-none d-md-flex flex-column flex-md-row">
        <span class="order-1 order-md-12 my-3 my-md-0">© 2023 Metascan Lab</span>
        <a href="mailto:team@metascan.pro" class="order-12 order-md-1"><em><img src="../assets/base/lib/mail.png" /></em>team@metascan.pro</a>
      </div>
    </a-layout-content>
  </a-layout-content>

  <a-modal
      v-model:visible="modal.visible"
      :wrap-style="{ overflow: 'hidden' }"
      :footer="null"
      width="660px"
      @ok="handleOk"
    >
      <template #closeIcon>
        <img src="../assets/base/lib/icon_close.png" />
      </template>
      <div class="mx-0 mx-lg-5 px-5 mb-5 d-flex flex-column align-items-center">
        <div class="col-4 col-sm-3 col-md-3 cover mx-auto">
          <img v-if="modal.cover === 'metascan'" class="drop-metascan" src="../assets/base/lib/ball_metascan.png" />
          <img v-if="modal.cover === 'twitterscan'" class="drop-twitterscan" src="../assets/base/lib/ball_twitterscan.png" />
          <img v-if="modal.cover === 'register3'" class="drop-register3" src="../assets/base/lib/ball_register3.png" />
          <img v-if="modal.cover === 'nns'" class="drop-nns" src="../assets/base/lib/ball_nns.png" />
          <img v-if="modal.cover === 'ethgame'" class="drop-ethgame" src="../assets/base/lib/ball_ethgame.png" />
          <!-- <img v-if="modal.cover === 'mojor'" class="drop-mojor" src="../assets/base/lib/ball_mojor.png" /> -->
        </div>
        <h3 class="m-fw-700">{{ modal.title }}</h3>
        <p v-for="(item, idx) in modal.content" :key="'i-' + idx">{{ item }}</p>
        <div class="links d-flex flex-column flex-lg-row col-12">
          <div v-if=modal.links.web class="col-12 col-lg"><a :href=modal.links.web target="_blank"><img src="../assets/base/lib/icon_web.png" />Web</a></div>
          <div v-if=modal.links.twitter class="col-12 col-lg"><a :href=modal.links.twitter target="_blank"><img src="../assets/base/lib/icon_twitter.png" />Twitter</a></div>
          <div v-if=modal.links.discord class="col-12 col-lg"><a :href=modal.links.discord target="_blank"><img src="../assets/base/lib/icon_discord.png" />Discord</a></div>
        </div>
      </div>
    </a-modal>
</template>

<script>
import { Layout, Button, Modal, Tabs, Input, message } from 'ant-design-vue'
import Busy from '../components/busy'
import ButtonBusy from '../components/button-busy'
import { parseAntComponent } from '../utils/helpers'
import { iLogoAll } from '../components/icons'

export default {
  components: {
    ...parseAntComponent([Layout.Content, Button, Modal, Tabs, Tabs.TabPane, Input]),
    iLogoAll
  },
  data() {
    return {
      modal: {
        visible: false,
        cover: 'metascan',
        title: '',
        content: [''],
        links: {
            web: '',
            twitter: '',
            discord: ''
          }
      }
    }
  },
  async mounted () {
  },
  methods: {
    onModal(cover) {
      const covers = {
        metascan: {
          title: 'MetaScan',
          content: ['Metascan group is an incubation hub dedicated to cultivating and supporting web3 innovation.'],
          links: {
            web: 'https://metascan.pro',
            // twitter: 'https://twitter.com/metascanpro',
            twitter: '',
            discord: ''
          }
        },
        twitterscan: {
          title: 'TwitterScan',
          content: ['Twitterscan is the most popular cryptocurrency social sentiment analysis platform based on Twitter.', 'It is a raddar for finding the current and next big thing in cryptocurrency.'],
          links: {
            web: 'https://twitterscan.com',
            twitter: 'https://twitter.com/metascanpro',
            discord: 'https://discord.gg/twitterscan'
          }
        },
        // register3: {
        //   title: 'Register3',
        //   content: ['Registry3 is a DAO and layerzero blockchain solution created to build a stable and dispute free domain name system.'],
        //   links: {
        //     web: 'https://registry3.com/',
        //     twitter: '',
        //     discord: ''
        //   }
        // },
        nns: {
          title: 'NNS',
          content: ['NNS is a cross-chain doamin name service that will connect web3.0 users, data, and assets.'],
          links: {
            web: 'https://nns.network/',
            twitter: 'https://twitter.com/nftnameservice',
            discord: 'https://discord.gg/twitterscan'
          }
        },
        mojor: {
          title: 'MOJOR',
          content: ['MOJOR is a community platform built for Web3 natives that offer Web3 developers technical help, community creation, governance, growth, and data services.'],
          links: {
            web: 'https://mojor.com/'
          }
        },
        // ethgame: {
        //   title: 'ETH.GAME',
        //   content: ['ETH.GAME, backed by professional game producers, is committed to building a Web3 game platform that connects Web2 & Web3 players, games, and guilds.'],
        //   links: {
        //     web: 'https://eth.game/'
        //   }
        // }
      }

      if (covers[cover]) {
        this.modal.cover = cover

        this.modal.title = covers[cover].title
        this.modal.content = covers[cover].content
        this.modal.links = covers[cover].links
        this.modal.visible = true;
      }
    }
  },
  computed: {
    // 结构性
    structure() {
      return {
      }
    },

    ixd() {
      return {
      }
    }
  },
}
</script>


<style lang="less">
.ant-modal-root {
  .ant-modal {
    top: 20%;
    margin-top: 100px;
    .ant-modal-content {
      .ant-modal-close {
        top: 12px;
        right: 12px;
      }
      border-radius: 12px;
      .ant-modal-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .cover {
          margin-top: -64px;
          margin-bottom: 36px;
          img {
            width: 100%;
            height: 100%;
          }
          .drop-mojor {
            width: 117px !important;
            height: 117px !important;
            // margin-left: -48px;
          }
          .drop-ethgame {
            width: 117px !important;
            height: 117px !important;
            // margin-left: -43px;
          }
        }
        h3 {
          font-size: 26px;
          line-height: 32px;
          margin-bottom: 22px;
        }
        p {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 12px;
        }
        .links {
          margin: 20px -12px 0;
          a {
            color: #2f2f2f;
            font-size: 14px;
            display: flex;
            padding: 12px 0;
            align-items: center;
            justify-content: center;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.07);
            box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.04);
            border-radius: 28px;
            margin: 12px;
            transition: all 0.5s;
            img {
              margin-right: 6px;
            }
            &:hover {
              border: 1px solid rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 996px) {
  .ant-modal {
    max-width: calc(100vw) !important;
    margin: 0px auto !important;
  }
}
@media (max-width: 660px) {
  .ant-modal {
    max-width: calc(100vw) !important;
    margin: 0px auto !important;
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    padding-bottom: 0px !important;
    .ant-modal-content {
      height: 100%;
      border-radius: 0 !important;
      .cover {
        margin-top: 50px !important;
      }
    }
  }
}

</style>
<style scoped lang="less">
.lib {
  background: linear-gradient(90deg, #B3E1F9 1.03%, #FCF2E8 99.13%);
  .libox {
    .logo {
      position: fixed;
      top: 28px;
      left: 28px;
      z-index: 1;
    }
    background:url('../assets/base/lib/top_bg.png') no-repeat top,
    url('../assets/base/lib/bottom_bg.png') repeat-x bottom left;
    background-size: cover;
    ul {
      position: relative;
      li {
        position:absolute;
        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
          transition: all 2s;
          &:hover {
            // animation: pellet 1.25s  alternate infinite linear;
          }
        }
        .m-fw-700 {
          color: #3838af;
        }
        &.non {
          img:not(.non) {
            cursor: default;
            animation: none ;
          }
        }
        .bubble-container {
          position: relative;
          z-index: 1;
            // background: radial-gradient(circle at 75% 30%,#fff 5px,#ff21c0 8%,
            // #5b5b5b 60%,#ff21c0 100%);
            // border-radius: 50%;
            // box-shadow: inset 0 0 20px #fff,
            //             inset 10px 0 46px #eaf5fc,
            //             inset 80px 0 60px #efcde6,
            //             inset -20px -60px 100px #f9f6de,
            //             inset 0px 50px 140px #f9f6de,
            //             0 0 90px #fff;
            // animation: bubble-boll 4s ease-in-out infinite;
        }
        .bubble-shadow {
          z-index: 0;
          position: absolute;
          background: linear-gradient(0deg, rgba(104, 128, 235, 0.13) 0%, rgba(104, 128, 235, 0) 89.17%);
          // background-color: red;
          border-radius: 50%;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          // bottom: -120px;
          filter: blur(1px);
          // animation: bubble-shadow 6s ease-in-out infinite;
        }
        &.ball_metascan {
          z-index: 1;
          left: 7%;
          // bottom: 248px;
          bottom: 27%;
          width: 22%;
          // height: 22%;
          animation: bubble-boll-mark 6s ease-in-out infinite;
          .fw-500 {
            font-size: 18px;
          }
          > div:nth-child(2) {
            position: absolute;
            right: 0;
            margin-right: -230px;
            padding-left: 54px;
            padding-right: 4px;
            background: url('../assets/base/lib/ball_metascan_tbg.png') no-repeat right 20px;
            > div {
              font-size: 32px;
              padding-bottom: 12px;
              background: linear-gradient(90deg, #425EE9 5.75%, #60C4F1 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
            > span {
              font-size: 18px;
              color: #7A78BB;
            }
          }
          > .bubble-shadow {
            width: 90%;
            margin-top: 24px;
            // height: 146px;
            height: 40%;
            animation: bubble-shadow-mark 6s ease-in-out infinite;
          }
        }
        &.ball_mojor {
          z-index: 1;
          left: 45%;
          bottom: 47%;
          width: 9%;
          // height: 8%;
          animation: bubble-boll 4s ease-in-out infinite;
          .fw-500 {
            font-size: 18px;
          }
          > div:nth-child(2) {
            position: absolute;
            left: 0;
            margin-left: -155px;
            top: 84%;
            margin-top: -16px;
            padding-top: 22px;
            padding-right: 46px;
            padding-left: 4px;
            background: url('../assets/base/lib/ball_mojor_tbg.png') no-repeat left center;
            > div {
              font-size: 22px;
              padding-bottom: 16px;
              color: #3838AF;
            }
            > span {
              display: inline-block;
              width: 173px;
              font-size: 14px;
              color: #7A78BB;
            }
          }
        }
        &.ball_nns {
          z-index: 1;
          left: 45%;
          bottom: 51%;
          width: 9%;
          animation: bubble-boll 4s ease-in-out infinite;
          .fw-500 {
            font-size: 18px;
          }
          > div:nth-child(2) {
            position: absolute;
            left: 0;
            margin-left: -233px;
            top: -4%;
            margin-top: -16px;
            padding-right: 46px;
            padding-left: 4px;
            background: url('../assets/base/lib/ball_common_tbg.png') no-repeat left center;
            > div {
              font-size: 22px;
              padding-bottom: 16px;
              color: #3838AF;
            }
            > span {
              display: inline-block;
              width: 173px;
              font-size: 14px;
              color: #7A78BB;
            }
          }
        }
        &.ball_register3 {
          z-index: 1;
          right: 34%;
          bottom: 63%;
          width: 7.5%;
          // height: 9%;
          animation: bubble-boll 4.5s ease-in-out infinite;
          .fw-500 {
            font-size: 18px;
          }
          > div:nth-child(2) {
            position: absolute;
            left: 0;
            margin-left: -220px;
            top: -55%;
            margin-top: -16px;
            padding-top: 8px;
            padding-right: 33px;
            background: url('../assets/base/lib/ball_registry3_tbg.png') no-repeat left 42px;
            > div {
              font-size: 22px;
              padding-bottom: 16px;
              color: #3838AF;
            }
            > span {
              display: inline-block;
              width: 220px;
              font-size: 14px;
              color: #7A78BB;
            }
          }
          > .bubble-shadow {
            width: 60%;
            margin-top: 80px;
            // height: 71px;
            top: 222%;
            height: 40%;
            animation: bubble-shadow 4.5s ease-in-out infinite;
          }
        }
        &.ball_twitterscan {
          z-index: 1;
          right: 7%;
          bottom: 29%;
          width: 12%;
          animation: bubble-boll 5s ease-in-out infinite;
          .fw-500 {
            font-size: 18px;
          }
          > div:nth-child(2) {
            position: absolute;
            left: 0;
            margin-left: -193px;
            top: -20%;
            margin-top: -16px;
            padding-right: 54px;
            padding-left: 4px;
            background: url('../assets/base/lib/ball_common_tbg.png') no-repeat left 32px;
            > div {
              font-size: 22px;
              padding-bottom: 16px;
              color: #3838AF;
            }
            > span {
              display: inline-block;
              width: 175px;
              font-size: 14px;
              color: #7A78BB;
            }
          }
        }
        &.ball_ethgame {
          z-index: 5;
          right: 31%;
          bottom: 20%;
          width: 12%;
          animation: bubble-boll 5s ease-in-out infinite;
          .fw-500 {
            font-size: 18px;
          }
          > div:nth-child(2) {
            position: absolute;
            margin-left: 162px;
            top: 70%;
            right: 0;
            padding-top: 21px;
            margin-right: -230px;
            padding-right: 4px;
            padding-left: 50px;
            background: url('../assets/base/lib/ball_metascan_tbg.png') no-repeat left 32px;
            > div {
              font-size: 22px;
              padding-bottom: 16px;
              color: #3838AF;
              text-fill-color: transparent;
            }
            > span {
              display: inline-block;
              width: 172px;
              font-size: 14px;
              color: #7A78BB;
            }
          }
        }
        &.ball_whale {
          z-index: 1;
          right: 35%;
          bottom: 216px;
          width: 6%;
          animation: bubble-boll 3.5s ease-in-out infinite;
        }
        &.ball_metascan_shadow {
          left: 47%;
          bottom: 53%;
          width: 7.5%;
          > .bubble-shadow {
            position: absolute;
            left: 0;
            /* margin-left: -183px; */
            /* top: -42%; */
            /* margin-top: -16px; */
            padding-top: 8px;
            padding-right: 46px;
            padding-left: 4px;
            width: 60%;
            top: 228%;
            /* margin-top: 52px; */
            height: 30px;
            animation: bubble-shadow 4s ease-in-out infinite;
          }
        }
        &.ball_mojor_shadow {
          left: 45%;
          bottom: 38%;
          width: 10%;
          > .bubble-shadow {
            position: absolute;
            left: 0;
            /* margin-left: -183px; */
            /* top: -42%; */
            /* margin-top: -16px; */
            padding-top: 8px;
            padding-right: 46px;
            padding-left: 4px;
            width: 60%;
            top: 228%;
            /* margin-top: 52px; */
            height: 30px;
            animation: bubble-shadow 4s ease-in-out infinite;
          }
        }
        &.ball_ethgame_shadow {
          right: 29%;
          bottom: 16%;
          width: 15%;
          > .bubble-shadow {
            position: absolute;
            left: 0;
            /* margin-left: -183px; */
            /* top: -42%; */
            /* margin-top: -16px; */
            padding-top: 8px;
            padding-right: 46px;
            padding-left: 4px;
            width: 60%;
            top: 228%;
            /* margin-top: 52px; */
            height: 48px;
            animation: bubble-shadow 4s ease-in-out infinite;
          }
        }
        &.ball_register3_shadow {
          right: 31.5%;
          bottom: 47%;
          width: 8.5%;
          > .bubble-shadow {
            position: absolute;
            left: 0;
            /* margin-left: -183px; */
            /* top: -42%; */
            /* margin-top: -16px; */
            padding-top: 8px;
            padding-right: 46px;
            padding-left: 4px;
            width: 60%;
            top: 228%;
            /* margin-top: 52px; */
            height: 34px;
            animation: bubble-shadow 4s ease-in-out infinite;
          }
        }
        &.ball_twitterscan_shadow  {
          right: 6%;
          bottom: 140px;
          width: 14%;
          height: 150px;
          > .bubble-shadow {
            width: 70%;
            margin-top: 76px;
            // height: 102px;
            height: 40%;
            animation: bubble-shadow 5s ease-in-out infinite;
          }
        }
        &.ball_whale_shadow {
          right: 35%;
          bottom: 130px;
          width: 6%;
          height: 80px;
          > .bubble-shadow {
            width: 80%;
            margin-top: 52px;
            // height: 36px;
            height: 40%;
            animation: bubble-shadow 3.5s ease-in-out infinite;
          }
        }
      }
    }
    .copyright {
      position: fixed;
      font-size: 14px;
      color: #2F2F2F;
      justify-content: space-between;
      padding: 0 28px;
      bottom: 12px;
      width: 100%;
      align-items: center;
      z-index: 1;
      a {
        display: flex;
        color: #2f2f2f;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.07);
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        align-items: center;
        padding-right: 14px;
        transition: all 0.5s;
        em {
          border-radius: 0;
          padding: 9px;
          border-right: 1px solid rgba(0, 0, 0, 0.07);
          margin-right: 14px;
        }
        &:hover {
          border: 1px solid rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .lib, .libox {
    background: linear-gradient(90deg, #B8E2F9 11.26%, #FCF2E8 96.03%);
  }
  .copyright {
    margin-top: 48px;
    position: relative !important;
  }
}

@media (min-width: 560px) {
  .mobile {
    li {
      span {
        margin-top: 18px;
        font-size: 24px;
        color: #3838AF;
        margin-bottom: 16px;
      }
      p {
        color: #7A78BB;
        font-size: 16px !important;
        line-height: 20px !important;
      }
      &:nth-child(1) {
        span {
          font-size: 28px !important;
          color: #3838AF;
          margin-bottom: 16px;
        }
      }
    }
  }
}

.mobile {
  background: linear-gradient(90deg, #B8E2F9 11.26%, #FCF2E8 96.03%);
  ol {
    margin: 0;
    padding: 0;
  }
  li {
    position: relative;
    padding: 42px 42px;
    display: flex;
    justify-content: center;
    margin-top: -5px;
      // linear-gradient(0deg, rgba(255,255,255,0.1) 11.26%, rgba(255,255,255,0.5) 96.03%);
    &:nth-child(1) {
      background: url('../assets/base/lib/mobile_unit_bg1.png') no-repeat center bottom;
      background-size: cover;
      z-index: 4;
      // background: rgba(255, 255, 255, 0.44);
      .m-fw-700 {
        margin-top: 24px;
      }
      span {
        font-size: 28px;
        line-height: 34px;
        background: linear-gradient(90deg, #425EE9 5.75%, #60C4F1 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        color: #3838af;
      }
      p {
        font-size: 16px;
        line-height: 18px;
      }
      img {
        filter: drop-shadow(0px 28px 25px rgba(101, 149, 231, 0.33));
      }
    }
    &:nth-child(2) {
      background: url('../assets/base/lib/mobile_unit_bg2.png') no-repeat center bottom;
      background-size: cover;
      z-index: 5;
      > div:nth-child(1) {
        background: url('../assets/base/lib/ball_common_tbg.png') no-repeat left 40px;
        background-size:contain;
      }
    }
    &:nth-child(3) {
      background: url('../assets/base/lib/mobile_unit_bg4.png') no-repeat center bottom;
      background-size: cover;
      z-index: 4;
      > div:nth-child(1) {
        background: url('../assets/base/lib/ball_common_tbg.png') no-repeat left 40px;
        background-size:contain;
      }
    }
    &:nth-child(4) {
      background: url('../assets/base/lib/mobile_unit_bg2.png') no-repeat center bottom;
      background-size: cover;
      z-index: 3;
      > div:nth-child(1) {
        background: url('../assets/base/lib/ball_common_tbg.png') no-repeat left 40px;
        background-size:contain;
      }
    }
    &:nth-child(5) {
      background: url('../assets/base/lib/mobile_unit_bg3.png') no-repeat center bottom;
      background-size: cover;
      z-index: 2;
      > div:nth-child(1) {
        background: url('../assets/base/lib/ball_common_tbg.png') no-repeat left 40px;
        background-size:contain;
      }
    }
    &:nth-child(6) {
      background: url('../assets/base/lib/mobile_unit_bg4.png') no-repeat center bottom;
      background-size: cover;
      z-index: 1;
      > div:nth-child(1) {
        background: url('../assets/base/lib/ball_common_tbg.png') no-repeat left 40px;
        background-size:contain;
      }
    }
    span {
      margin-top: 12px;
      color: #3838AF;
      margin-bottom: 16px;
    }
    p {
      color: #7A78BB;
      font-size: 12px;
      line-height: 14px;
      padding-right: 10%;
    }
    img {
      cursor: pointer;
      width: 100%;
    }
    .col-7 {
      // margin-top: 12px;
      display: flex;
      flex-direction: column;
    }
  }
}

.drop-twitterscan {
  filter: drop-shadow(0px 28px 25px rgba(111, 216, 228, 0.34));
}
.drop-register3 {
  filter: drop-shadow(0px 28px 25px rgba(100, 104, 231, 0.34));
}
.drop-nns {
  filter: drop-shadow(0px 28px 25px rgba(101, 149, 239, 0.33));
}
.drop-ethgame {
  filter: drop-shadow(0px 28px 25px rgba(186, 218, 255, 0.33));
}
.drop-mojor {
  filter: drop-shadow(0px 28px 25px rgba(49, 117, 219, 0.33));
}
.drop-metascan {
  filter: drop-shadow(0px 28px 25px rgba(101, 149, 231, 0.33));
}

// @keyframes bubble-boll-mark {
//   0%,100%{
//     transform: translateY(0);
//   }
//   50%{
//     transform: translateY(-20px);
//   }
// }
// @keyframes bubble-shadow-mark {
//   0%,100%{
//     transform: scale(1);
//   }
//   50%{
//     transform: scale(0.7);
//   }
// }
// @keyframes bubble-boll {
//   0%,100%{
//     transform: translateY(0);
//   }
//   50%{
//     transform: translateY(-12px);
//   }
// }

// @keyframes bubble-shadow {
//   0%,100%{
//     transform: scale(1);
//   }
//   50%{
//     transform: scale(0.85);
//   }
// }

@keyframes pellet {
  0%{
    border-radius: 50%;
    transform: translateY(0) scaleX(1);
    opacity: 1;
  }
  40%{
    border-radius: 50%;
    transform: translateY(-10px) scaleX(0.97);
    opacity: 0.75;
  }
  100%{
    border-radius: 50% ;
    transform: translateY(0px) scaleX(1.03);
    opacity: 0.95;
  }
}




</style>
