

<template>
  <a-config-provider :locale="i18n.language.ant">
    <a-layout class="container-fluid px-0 min-100vh" :data-sw="swUpdate">
      <router-view />
    </a-layout>
  </a-config-provider>
</template>

<script>
/** @format */

import { Layout, ConfigProvider } from 'ant-design-vue'
import { parseAntComponent } from './utils/helpers'
import update from '../src/utils/update'

export default {
  components: {
    ...parseAntComponent([Layout, ConfigProvider]),
  },
    mixins: [update],

  computed: {
    i18n() {
      const { i18n } = this.$store
      // TODO:
      i18n.locale = this.$i18n.locale

      return i18n
    },
    swUpdate() {
      console.log('this.updateExists', this.updateExists)
      // Service workers 有更新时，则自动触发更新，并同步页面
      if (this.updateExists) {
        this.refreshApp()
      }

      return this.updateExists
    },
  },
}
</script>

<style lang="less" scoped>
/** @format */
</style>