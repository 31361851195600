/** @format */

import { reactive } from 'vue'
import i18n from '../i18n'
import wallet from './wallet'
import announcements from './announcements'
import nftSwaps from './nftSwaps'

export default reactive({
  i18n,
  announcements,
  // wallet,
  // nftSwaps
})
