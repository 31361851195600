/** @format */

// import BN from 'BN.js'
import ModelState from '../base/state'

import { formatNumber, floor } from '../../utils'

const ModelValueDate = {
  /**
   * @return {!Object}
   */
  create({ handled = undefined, viewDefault = '-', viewMethod = floor } = {}) {
    const __store__ = {
      handled: '',
      view: viewDefault,
    }

    const model = {
      /** @type {boolean} */
      loading: true,
      _update() {
        const { loading } = this

        // init
        if (loading) {
          this.loading = false
        }
      },

      /**
       * Universal data
       * @type {(string|number)}
       */
      get value() {
        return this.handled
      },
      set value(val) {
        this.handled = val
      },

      /** @type {string|number} */
      get handled() {
        return __store__.handled
      },
      set handled(val) {
        const { state } = this

        __store__.handled = val

        this._update()
        state.afterUpdate()
      },

      /** @type {string} */
      get view() {
        const { handled, loading, state } = this

        return state.updated ? formatNumber(handled) : __store__.view
      },

      state: ModelState.create(),
    }

    return model
  },
}

export default ModelValueDate
