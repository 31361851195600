<template>
  <a-layout-content class="container-fluid px-0 coverBg">
    <a-layout-content class="container-lg d-flex flex-column flex-md-row ps-4 ps-md-5 py-5 my-md-5">
      <div class="d-flex flex-column col-12 col-md-6 order-1 order-md-12">
        <span class="fw-bold py-4 banner-title">
          A <span class="line-frame-highlight px-2 d-inline-block">Layer Zero</span>
          Protocol for Decentralized Social Network
        </span>

        <div class="d-flex gap-3">
          <a-button size="small" type="secondary" class="d-flex align-items-center">
            <a class="d-flex align-items-center fw-bold" href="https://twitter.com/MetascanPro" target="_blank">
              <iTwitter class="me-2" />Twitter
            </a>
          </a-button>
          <a-button size="small" type="secondary" class="d-flex align-items-center">
            <a class="d-flex align-items-center fw-bold" href="https://discord.com/invite/twitterscan" target="_blank">
              <iDiscord class="me-2" />Discord
            </a>
          </a-button>
        </div>
      </div>
      <div class="col-12 col-md order-12 order-md-1">
        <img class="w-100p d-md-none" src="../assets/base/home/banner.png" />
      </div>
    </a-layout-content>
  </a-layout-content>

  <!-- <a-layout-content class="container-lg px-0 coverBg">
    <a-layout-content class="container-lg d-flex flex-column flex-md-row ps-4 ps-md-5 py-0 my-md-5">
      <div class="d-flex flex-column col-12 col-md-8 order-12 order-md-1 banner-box">
        <span class="h1 fw-bold">Connect WEB 2.0 </span>
        <span class="h1 fw-bold pt-1 pt-md-4">Grow WEB <span class="text-color-blue">3.0</span></span>
        <div class="d-flex gap-3 mt-3 mt-md-5 pt-2">
          <a-button size="small" type="secondary" class="d-flex align-items-center">
            <a class="d-flex align-items-center fw-bold" href="https://twitter.com/MetascanPro" target="_blank">
              <iTwitter class="me-2" />Twitter
            </a>
          </a-button>
          <a-button size="small" type="secondary" class="d-flex align-items-center">
            <a class="d-flex align-items-center fw-bold" href="https://discord.gg/jyBKaEqX76" target="_blank">
              <iDiscord class="me-2" />Discord
            </a>
          </a-button>
        </div>
      </div>
      <div class="col-12 col-md order-1 order-md-12 mt-4 mt-md-0">
        <img class="w-100p d-md-none" src="../assets/base/home/banner.png" />
      </div>
    </a-layout-content>
  </a-layout-content> -->

  <a-layout-content class="container-fluid p-0">
    <div class="marquee overflow-hidden col-12 d-flex">
      <div class="d-flex" v-for="(item, idx) of structure.marquees" :key="'marquee' + idx">
        <span class="h4 fw-bold d-flex align-items-center me-4" v-for="(unit, i) of structure.marqueeUnit"
          :key="'marquee-' + idx + '-' + i">
          <component :is="unit.component" class="me-2"></component>
          {{ unit.text }}
        </span>
      </div>
    </div>
  </a-layout-content>

  <!-- <a-layout-content class="container-fluid p-0 ts-pass">
    <a-layout-content class="container-lg h-100 px-4 px-md-5">
      <div class="d-flex flex-column flex-md-row h-100 ts-pass-box">
        <span class="text-center col-5 col-md-5"><img src="../assets/base/home/ts_creator2.png"></span>
        <div class="d-flex flex-column col-12 col-md-7 ps-4 justify-content-center pb-5">
          <span class="h2 text-color-blank fw-bold pb-1 pb-md-3">TwitterScan Pass launch date: 30th September
            2022</span>
          <span class="h4 text-color-white fw-bold pt-1 pt-md-3 mt-1 mt-md-3">Holders Benefits:</span>
          <span class="h5 text-color-white mt-2 mt-md-3 d-flex"><span class="me-1">1.</span>Exclusive Feature: Eligible
            to register your desired 3-4 digits “.NFT” domain name on a priority basis.</span>
          <span class="h5 text-color-white mt-2 mt-md-3 d-flex"><span class="me-1">2.</span>Improve the odds of getting
            the whitelist to the Highlight NFT Collections.</span>
          <span class="h5 text-color-white mt-2 mt-md-3 d-flex"><span class="me-1">3.</span>Access to TwitterScan
            premium functions, get watchlist’s real-time alerts and discover more investment opportunities.</span>
          <span class="h5 text-color-white mt-2 mt-md-3 d-flex"><span class="me-1">4.</span>Unlock the upcoming useful
            data analysis features.</span>
        </div>
      </div>
    </a-layout-content>
  </a-layout-content> -->

  <a-layout-content class="container-fluid p-0 social-net">
    <a-layout-content class="container-lg h-100 px-4 px-md-5">
      <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between h-100">
        <img src="../assets/base/home/social_net.png">
        <div class="d-flex flex-column social-net-text-box">
          <span class="h2 fw-bold">AI Powered Investment decision-making tool built on Big Social Data</span>
          <span class="mt-2 mt-md-4 h4">Follow whale and smart money to maximize the alpha return</span>
        </div>
      </div>
    </a-layout-content>
  </a-layout-content>

  <a-layout-content class="container-fluid p-0 raffle-sys">
    <a-layout-content class="container-lg h-100 px-4 px-md-5">
      <div
        class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-center justify-content-md-between h-100">
        <div class="d-flex flex-column raffle-sys-text-box">
          <span class="h2 fw-bold">BI Solution: mentor and empower web3 projects for community growth</span>
          <span class="mt-2 mt-md-4 h4">Dedicated web3.0 raffle and giveaway system. An efficient KOL based marketing
            tool to grow the project from success to excellence. </span>
        </div>
        <img src="../assets/base/home/raffle_sys.png">
      </div>
    </a-layout-content>
  </a-layout-content>

  <a-layout-content class="container-fluid p-0 build-web">
    <a-layout-content class="container-lg h-100 px-4 px-md-5">
      <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between h-100">
        <img src="../assets/base/home/build_web.png">
        <div class="d-flex flex-column build-web-text-box">
          <span class="h2 fw-bold">Build Web3 social network based on .NFT DID</span>
          <span class="mt-2 mt-md-4 h4">A network that is scalable, user-oriental, privacy honoured, open and
            permissionless. It is the next-gen play ground for web 3.0 residents. </span>
        </div>
      </div>
    </a-layout-content>
  </a-layout-content>

  <a-layout-content class="container-fluid p-0"
    :class="{ 'traitsBg_c1': currStep !== '3' && currStep !== '4', 'traitsBg_c3': currStep === '3', 'traitsBg_c4': currStep === '4' }">
    <a-layout-content class="container-lg px-0 py-5 my-md-5">
      <div class="d-flex flex-column px-4 px-md-5 main__graphic justify-content-center"
        :class="{ 'traitsBg_1': currStep !== '3' && currStep !== '4', 'traitsBg_3': currStep === '3' }">
        <div class="traits d-flex flex-column flex-md-row">
          <template v-if="currStep !== '2' && currStep !== '3' && currStep !== '4'">
            <div class="d-flex flex-column justify-content-center col-12 col-md-4 pb-4 pe-md-4">
              <ul class="nav d-none d-md-flex flex-column">
                <li class="h6 curr">MetaScan</li>
                <li class="h6">MetaScan</li>
                <li class="h6">TwitterScan</li>
                <li class="h6">Dao</li>
              </ul>
              <span class="h2 fw-bold">MetaScan</span>
              <h4>A user centered social trading and content creation network</h4>
            </div>
            <div class="col-12 col-sm-11 col-md-8 mx-auto d-flex align-items-center">
              <img class="w-100p" src="../assets/base/home/traits_1@2x.png" />
            </div>
          </template>

          <template v-if="currStep === '2'">
            <div class="d-flex flex-column justify-content-center col-12 col-md-4 pb-4 pe-md-4">
              <ul class="nav d-none d-md-flex flex-column">
                <li class="h6">MetaScan</li>
                <li class="h6 curr">MetaScan</li>
                <li class="h6">TwitterScan</li>
                <li class="h6">Dao</li>
              </ul>
              <span class="h2 fw-bold">MetaScan</span>
              <h4>A user centered social trading and content creation network</h4>
            </div>
            <div class="col-12 col-sm-11 col-md-8 mx-auto d-flex align-items-center">
              <img class="w-100p" src="../assets/base/home/traits_2@2x.png" />
            </div>
          </template>

          <template v-if="currStep === '3'">
            <div class="d-flex flex-column justify-content-center col-12 col-md-4 pb-4 pe-md-4">
              <ul class="nav d-none d-md-flex flex-column">
                <li class="h6">MetaScan</li>
                <li class="h6">MetaScan</li>
                <li class="h6 curr">TwitterScan</li>
                <li class="h6">Dao</li>
              </ul>
              <span class="h2 fw-bold">Twitterscan</span>
              <h4>Twitterscan is the pioneer app dedicated to the metascan adventurers. It aims to keep track of
                aggregated market sentiments and Present user friendly trading signs.</h4>
            </div>
            <div class="col-12 col-sm-11 col-md-8 mx-auto ">
              <img class="w-100p" src="../assets/base/home/traits_3@2x.png" />
              <ul class="d-flex ms-auto me-md-4 flex-column traits_3 gap-3 col-12 col-md-9 col-lg-8 col-xl-7">
                <li>
                  <iRight class="col-auto" /><span>Twitter’s most influential mastermind</span>
                </li>
                <li>
                  <iRight class="col-auto" /><span>Project life cycle tracking</span>
                </li>
                <li>
                  <iRight class="col-auto" /><span>Trending coin</span>
                </li>
                <li>
                  <iRight class="col-auto" /><span>Bull/Bear sentiment analysis</span>
                </li>
                <li>
                  <iRight class="col-auto" /><span>Project community involvement index(including fans engagements,
                    marketwise cooperation, brand awareness and etc. )</span>
                </li>
              </ul>
            </div>
          </template>

          <template v-if="currStep === '4'">
            <div class="d-flex flex-column justify-content-center col-12 col-md-4 pb-4 pe-md-4">
              <ul class="nav d-none d-md-flex flex-column">
                <li class="h6">MetaScan</li>
                <li class="h6">MetaScan</li>
                <li class="h6">TwitterScan</li>
                <li class="h6 curr">Dao</li>
              </ul>
              <span class="h2 fw-bold">Dao</span>
              <h4>Decentralized Autonomous Organizations, or DAOs for short, are self-governing organizations that
                enable groups of like-minded individuals to work towards a mutual goal. Metascan Dao is the central
                governance hub to pave a way for a community owned organization.</h4>
            </div>
            <div class="col-12 col-sm-11 col-md-8 mx-auto d-flex align-items-center">
              <img class="w-100p" src="../assets/base/home/traits_4@2x.png" />
            </div>
          </template>
        </div>
      </div>
      <Scrollama :debug="false" :offset="10" @step-enter="({ element }) => (currStep = element.dataset.stepNo)">
        <div class="step" data-step-no="1"></div>
        <div class="step" data-step-no="2"></div>
        <div class="step" data-step-no="3"></div>
        <div class="step" data-step-no="4"></div>
      </Scrollama>
    </a-layout-content>
  </a-layout-content>
  
  <a-layout-content class="benefitsBg container-fluid d-flex flex-column p-0">
    <div class="container-lg d-flex flex-column flex-lg-row px-4 col-12">
      <h2 class="d-flex fw-bold text-center text-lg-start col-12 col-lg-4">
        <span class="col-12 col-lg-9 py-3">Get Exclusive Benefits</span>
      </h2>
      <ul class="d-flex flex-wrap col-12 col-lg-8">
        <li class="d-flex col-12 col-md-6 py-3 pe-4">
          <span><iMintBird /></span>
          <span class="d-flex flex-column ms-3">
            <h4 class="fw-bold">Early Access</h4>
            <span>Get early access to upcoming TwitterScan web3 domains launch before anyone else has access to it.</span>
          </span>
        </li>
        <li class="d-flex col-12 col-md-6 py-3 pe-4">
          <span><iMintPremium /></span>
          <span class="d-flex flex-column ms-3">
            <h4 class="fw-bold">Premium Features</h4>
            <span>TwitterScan's premium product features</span>
          </span>
        </li>
        <li class="d-flex col-12 col-md-6 py-3 pe-4">
          <span><iMintWhitelist /></span>
          <span class="d-flex flex-column ms-3">
            <h4 class="fw-bold">Get whitelist</h4>
            <span>Entitled to get whitelist for projects raffled in TwitterScan</span>
          </span>
        </li>
        <li class="d-flex col-12 col-md-6 py-3 pe-4">
          <span><iMintBenefits /></span>
          <span class="d-flex flex-column ms-3">
            <h4 class="fw-bold">Other benefits</h4>
            <span>TwitterScan PASS holders are eligible to renew TwitterScan web3 domains name services with a 50% discount.</span>
          </span>
        </li>
      </ul>
    </div>
  </a-layout-content>
  <!-- Road Map -->
  <a-layout-content class="container-fluid p-0">
    <a-layout-content class="container-lg px-4 px-md-5 py-5 my-md-5">
      <span class="h2 fw-bold d-block  text-center pb-4 mb-3">Road Map</span>
      <ul class="roadMap d-flex flex-column flex-md-row gap-4">
        <li class="d-flex flex-column col">
          <span class="h4 d-flex flex-wrap align-items-center fw-bold">
            <span class="pe-2">2022 Q3</span>
            <!-- <em class="primary3">Beta</em> -->
          </span>
          <ol>
            <li class="d-flex" data-idx="1">Launch TwitterScan utility NFT PASS</li>
            <li class="d-flex" data-idx="2">Implement dynamic whales analysis</li>
            <li class="d-flex" data-idx="3">Improve on-chain index for smart money and whale</li>
            <li class="d-flex" data-idx="4">Whale based NFT launch platform</li>
            <li class="d-flex" data-idx="5">Chrome plug-in tool for NFT sniper</li>
          </ol>
        </li>
        <li class="d-flex flex-column col">
          <span class="h4 d-flex flex-wrap align-items-center fw-bold">
            <span class="pe-2">2022 Q4</span>
            <!-- <em class="primary3">V1</em> -->
          </span>
          <ol>
            <li class="d-flex" data-idx="1"><span>Provide the trading signal for web3 users</span></li>
            <li class="d-flex" data-idx="2">Authentication and voting function for crypto projects</li>
            <li class="d-flex" data-idx="3">Customized portfolio profile for users</li>
          </ol>
        </li>
        <li class="d-flex flex-column col">
          <span class="h4 d-flex flex-wrap align-items-center fw-bold">
            <span class="pe-2">Future</span>
            <!-- <em class="primary3">V3</em> -->
          </span>
          <ol>
            <li class="d-flex" data-idx="1">DID: An ENS like system with verified identity</li>
            <li class="d-flex" data-idx="2">Social Graph: Bring existing web 2.0 connections to web 3.0</li>
            <li class="d-flex" data-idx="3">DAO Management: Build Web 3.0 native community</li>
            <li class="d-flex" data-idx="4">Credit System: Build credit rating system based on onchain transactions and
              DAO activities.</li>
          </ol>
        </li>
      </ul>
    </a-layout-content>
  </a-layout-content>

  <!-- 底部合作方 -->
  <a-layout-content class="container-fluid p-0">
    <a-layout-content class="container-lg px-4 px-md-5 py-5 my-md-5">
      <div class="d-flex flex-column mb-3">
        <span class="h4 d-flex flex-column">
          Backed by
          <span class="h2 fw-bold pt-3 pb-4">the most innovate and creative minds</span>
        </span>
      </div>
      <Partners class="backedLinks" />
    </a-layout-content>
  </a-layout-content>

</template>

<script>
import { Layout, Button, Popover, Tabs, Input, message } from 'ant-design-vue'
import Busy from '../components/busy'
import ButtonBusy from '../components/button-busy'
import { parseAntComponent } from '../utils/helpers'
import { Elevator } from '../utils/helpers/elevator'
import Scrollama from '../components/scrollama.vue'
import Partners from '../components/Partners.vue'

import {
  iBlockchain,
  iBnb,
  iCoinmarketcap,
  iDiscord,
  iDiscordCircle,
  iEtherscan,
  iFacebook,
  iMedium,
  iMenu,
  iNansen,
  iOpen,
  iOpensea,
  iPermissionless,
  iReddit,
  iTrustless,
  iTwitter,
  iYoutube,
  iRight,
  iMintBenefits,
  iMintBird,
  iMintPremium,
  iMintWhitelist,
} from '../components/icons';

export default {
  components: {
    ...parseAntComponent([Layout.Content, Button, Popover, Tabs, Tabs.TabPane, Input]),
    Busy,
    ButtonBusy,
    Scrollama,

    iBlockchain,
    iBnb,
    iCoinmarketcap,
    iDiscord,
    iDiscordCircle,
    iEtherscan,
    iFacebook,
    iMedium,
    iMenu,
    iNansen,
    iOpen,
    iOpensea,
    iPermissionless,
    iReddit,
    iTrustless,
    iTwitter,
    iYoutube,
    iRight,
    iMintBenefits,
    iMintBird,
    iMintPremium,
    iMintWhitelist,
    Partners
  },
  data() {
    return {
      currStep: null
    }
  },
  async mounted() {
  },
  methods: {

  },
  computed: {

    // 结构性
    structure() {

      return {
        marquees: Array(6).fill(''),
        marqueeUnit: [
          { component: iBlockchain, text: 'Blockchain' },
          { component: iOpen, text: 'Open' },
          { component: iTrustless, text: 'Trustless' },
          { component: iPermissionless, text: 'Permissionless' }
        ],
        revolutionAnimates: [
          { flow: ['col-8'], class: ['opacity-30', 'row1'] },
          { flow: ['col-9'], class: ['opacity-50 row2'] },
          { flow: ['col-10'], class: ['row3'], curr: 11, currText: 'USER A' },
          { flow: ['col-12'], class: ['row4'], curr: 38, currText: 'USER B' },
          { flow: ['col-10'], class: ['row5'], curr: 6, currText: 'USER C' },
          { flow: ['col-9'], class: ['opacity-50 row2'] },
          { flow: ['col-8'], class: ['opacity-30', 'row1'] },
        ],
        revolutionAnimateUnit: Array(50).fill('').map((item, i) => i % 2 ? 'Data' : 'Content')
      }
    },

    ixd() {
      return {
      }
    },
  },
}
</script>

<style scoped lang="less">

.benefitsBg {
  border-bottom: 1px solid #000000;
  > div {
      padding: 120px 0 104px;
      background: url('../assets/mint/benefits.png') no-repeat left bottom;
  }
}
@media (max-width: 992px) {
  .benefitsBg {
    > div {
      padding-bottom: 220px;
      background-position-x: center;
    }
  }
}

.banner-title {
  font-size: 48px;
  line-height: 56px;
}

.main__graphic {
  position: sticky;
  // top: 134px;
  // height: 75vh;
  top: 0px;
  height: 100vh;
}

.step {
  padding: 15vh 0;
  width: 50%;
  margin: 0 auto 30vh;
  // background-color: beige;
  // border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}


ol.broken {
  li {
    h4 {
      &::before {
        background: #3355FF;
        display: inline-block;
        content: attr(data-idx);
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-weight: bold;
        border-radius: 50%;
        color: #fff;
        font-family: 'Montserrat-Bold';
        margin-right: 16px;
        font-size: 16px;
      }
    }
  }
}

.marquee {
  padding: 12px 0;

  // border-top: 1px solid #0E1014;
  // border-bottom: 1px solid #0E1014;
  >div {
    flex: 0 0 auto;
    white-space: nowrap;
    user-select: none;
    animation: 30s linear 0s infinite normal none running animation-marquee-left;
  }
}

.revolution {
  overflow: hidden;

  li {
    overflow: hidden;
    margin: 0 auto;
    border-radius: 24px;

    >div {
      flex: 0 0 auto;
      white-space: nowrap;
      user-select: none;

      &.row1 {
        animation: 48s linear 0s infinite normal none running animation-marquee-left;
      }

      &.row2 {
        animation: 76s linear 0s infinite normal none running animation-marquee-right;
      }

      &.row3 {
        animation: 124s linear 0s infinite normal none running animation-marquee-left;
      }

      &.row4 {
        animation: 200s linear 0s infinite normal none running animation-marquee-right;
      }

      &.row5 {
        animation: 164s linear 0s infinite normal none running animation-marquee-left;
      }

      .curr {
        font-family: 'Montserrat-Bold';
        background: #fff;
        border: 1px solid rgba(14, 16, 20, 0.9);
        font-size: 20px;
        line-height: 24px;
        color: #3355FF;
      }
    }
  }
}

@keyframes animation-marquee-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-80%);
  }
}

@keyframes animation-marquee-right {
  0% {
    transform: translateX(-80%);
  }

  100% {
    transform: translateX(0);
  }
}

.banner-content {
  img {
    width: 670px;
    height: 550px;
  }
}

.ts-pass {
  background: #26A5BA;

  .ts-pass-box {
    padding-top: 55px;

    img {
      width: 376px;
      margin-left: -24px;
    }
  }
}

.social-net {
  background: #EDF9FF;
  height: 706px;

  img {
    width: 452px;
    height: 506px;
  }

  .social-net-text-box {
    width: 535px;
  }
}

.raffle-sys {
  background: #EDF1FB;
  height: 706px;

  img {
    width: 480px;
    height: 480px;
  }

  .raffle-sys-text-box {
    width: 446px;
  }
}

.build-web {
  height: 706px;
  background: #EDF9FF;

  img {
    width: 352px;
    height: 452px;
  }

  .build-web-text-box {
    width: 468px;
  }
}

.backedLinks {
  border: 1px solid rgba(14, 16, 20, 0.09);
  border-radius: 12px;

  span {
    &:hover {
      opacity: 0.85;
      // background-color: rgba(229,243,243,1);
    }
  }
}

.traits {
  ul.nav {
    position: absolute;
    top: 100px;

    margin-bottom: 124px;

    li {
      display: flex;
      color: rgba(14, 16, 20, 0.6);
      margin-bottom: 2px;
      line-height: 32px;

      &::before {
        content: '';
        margin-right: 8px;
        width: 4px;
        background-color: rgba(14, 16, 20, 0.15);
      }

      &.curr {
        font-family: 'Montserrat-Bold';
        color: #0E1014;

        &::before {
          background-color: #3355FF;
        }
      }
    }
  }
}

.traitsBg_c1 {
  background-color: #EBF8FF;
}

.traitsBg_1 {
  background: url('../assets/base/home/traitsBg_1.png') no-repeat left bottom;
}

.traitsBg_c3 {
  background-color: #EDFBFC;
}

.traitsBg_3 {
  background: url('../assets/base/home/traitsBg_3.png') no-repeat left bottom;
}

.traitsBg_c4 {
  background-color: #FFFBF0;
}

.traits_3 {
  margin-top: -200px;
  position: relative;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid rgba(14, 16, 20, 0.9);
  border-radius: 16px;

  li {
    display: flex;

    span {
      padding-left: 16px;
    }
  }
}

.coverBg {
  // padding-top: 130px;
  // padding-bottom: 230px;
  padding: 120px 0;
  background: url('../assets/base/home/banner.png') no-repeat 101%;
  background-size: 43%;
}

@media (min-width: 2000px) {
  .coverBg {
    background: url('../assets/base/home/banner.png') no-repeat 99%;
    background-size: contain;
  }
}

@media (max-width: 992px) {
  .coverBg {
    background: url('../assets/base/home/banner.png') no-repeat 100%;
    background-size: 50%;
  }
}

@media (max-width: 768px) {
  .traits_3 {
    margin-top: 24px;
  }

  .coverBg {
    padding-top: 48px;
    padding-bottom: 48px;
    background-size: 0;
  }
}

@media (max-width: 540px) {
  .banner-title {
    font-size: 40px;
    line-height: 48px;
  }

  .h1 {
    font-size: 40px;
    line-height: 48px;
  }

  .h2 {
    font-size: 26px;
    line-height: 30px;
  }

  .banner-box {
    .h1 {
      font-size: 22px;
      line-height: 28px;
    }
  }

  .ts-pass {
    .ts-pass-box {
      padding-top: 20px;
    }

    img {
      zoom: 0.5;
    }

    .h1 {
      font-size: 22px;
    }

    .h2 {
      font-size: 18px;
    }

    .h4 {
      font-size: 16px;
    }

    .h5 {
      font-size: 14px;
    }
  }

  .ts-pass,
  .social-net,
  .raffle-sys,
  .build-web {

    // height: 400px;
    img {
      zoom: 0.5;
    }

    .h1 {
      font-size: 22px;
    }

    .h2 {
      font-size: 18px;
    }

    .h4 {
      font-size: 16px;
    }

    .h5 {
      font-size: 14px;
    }
  }

  .social-net {
    .social-net-text-box {
      width: 100%;
      text-align: center;
    }
  }

  .raffle-sys {
    .raffle-sys-text-box {
      width: 100%;
      text-align: center;
    }
  }

  .build-web {
    .build-web-text-box {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
