<template>
  <a-layout-footer class="container-fluid p-0 bg-dust">
    <a-layout-content class="container-lg p-4 px-md-5 d-flex justify-content-between align-items-center flex-wrap">
      <span class="text-color-dust d-flex-column col">
        <span class="d-block">Copyright © 2021 ~ 2022 metascan technology lab</span>
      </span>
      <!-- <a-dropdown placement="topRight" class="ms-auto">
        <a-button size="small" class="d-flex align-items-center">
          <span class="h4 icon-language pe-2"></span>
          {{ i18n.baseLang.name }}
          <span class="h4 icon-select ps-2"></span>
        </a-button>
        <template #overlay>
          <a-menu>
            <a-menu-item
              v-for="(lang, name) of i18n.supports"
              :key="name"
              :value="name"
              @click="changedI18n">
              <span>{{ lang.name }}</span>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown> -->
    </a-layout-content>
  </a-layout-footer>
</template>

<script>
import { Button, Layout, Dropdown, Menu } from 'ant-design-vue'
import { parseAntComponent } from '../../utils/helpers'

export default {
  components: {
    ...parseAntComponent([Button, Layout.Footer, Dropdown, Menu, Menu.Item ]),
  },
  methods: {
    changedI18n({ key }) {
      this.$i18n.locale = key
    },
  },
  computed: {
    i18n () {
      return this.$store.i18n
    }
  }
};
</script>

<style lang="less" scoped>
</style>