<template>
  <a-layout-content class="container-fluid d-flex p-0 py-5 cover max-100vh">
    <a-layout-content class="container-lg d-flex flex-wrap ps-4 ps-md-5 align-items-center py-5">
      <div class="col-12 col-md-6 d-flex justify-content-md-end px-0 px-md-4 pb-4 pb-md-0">
        <iArtAmazingRocketExample class="mx-0 mx-md-4" />
      </div>
      <div class="d-flex flex-column col-12 col-md-6">
        <iArtAstronaut class="astronaut" />
        <span class="h1 d-flex flex-column fw-bold pb-3">
          Metascan<br/>
          Diamond Hand
        </span>
        <span class="h5 col-12 col-md-9 col-lg-8">
          <h5 class="mb-1">Diamond Hand is a collection of 100 algorithmically generated digital collectibles that represent the invitation-only membership pass to the Metascan Club.</h5>
        </span>
        <busy v-if="!ixd.mint.minted" :busying=ixd.mint.busy class="mt-4 col-12 col-md-7">
          <a-button :disabled=ixd.mint.disabled size="large" type="secondary" @click=onMintSpecialNFT class="d-flex align-items-center justify-content-center fw-bold col-12">
            {{ ixd.mint.text }}
          </a-button>
        </busy>
        <a-button v-else size="large" type="link" class="d-flex align-items-center justify-content-center fw-bold mt-4 col-12 col-md-7">
          <a class="d-flex align-items-center fw-bold" href="https://opensea.io/collection/metascan" target="_blank">
            <iOpensea class="me-2" />View OpenSea
          </a>
        </a-button>
      </div>
    </a-layout-content>
  </a-layout-content>
</template>

<script>
import axios from 'axios'
import { Layout, Button, Popover, Tabs, Input, message } from 'ant-design-vue'
import Busy from '../components/busy'
import ButtonBusy from '../components/button-busy'
import { parseAntComponent } from '../utils/helpers'
import { iArtAmazingRocketExample, iArtAstronaut, iOpensea } from '../components/icons'

export default {
  components: {
    ...parseAntComponent([Layout.Content, Button, Popover, Tabs, Tabs.TabPane, Input]),
    Busy,
    ButtonBusy,

    iArtAmazingRocketExample,
    iArtAstronaut,
    iOpensea
  },
  data() {
    return {
      currStep: null,
      specialSignCode: ''
    }
  },
  async mounted (e) {
    this.$store.nftSwaps.mintPasscard.specialSignCode = this.getQueryVariable('c') || ''
  },
  methods: {
     getQueryVariable(variable){
      let query=window.location.search.substring(1);
      let vars=query.split("&");
      for(let i=0,size=vars.length;i<size;i++){
        let param=vars[i];
        let index=param.indexOf("=");
        let pair=param.substring(0,index);
        if(pair==variable){
          return param.substring(index+1,param.length);
        }
      }
      return false;
    },

    async onMintSpecialNFT() {
      await this.$store.nftSwaps.mintPasscard.onMint()
    },
  },
  computed: {
    // 结构性
    structure() {

      return {
      }
    },

    ixd() {
      const { nftSwaps, wallet } = this.$store
      const cert = nftSwaps.mintPasscard.specialSignCode === ''

      const mint = {
        busy: nftSwaps.mintPasscard.state.busy,
        disabled: cert,
        // 已经铸造过
        minted: nftSwaps.mintPasscard.walletBalanceOf.handled > 0,
        text: cert
          ? 'Invitation Only'
          : 'Mint Now'
      }

      return {
        mintPasscard: nftSwaps.mintPasscard,
        wallet,
        mint,
      }
    }
  },
}
</script>

<style scoped lang="less">
.cover {
  background:
    url('../assets/base/nft/amazingNftBg.png') no-repeat left 0,
    linear-gradient(180deg, #8DABFA -3.9%, #F0F8FF 62.45%);
  background-size: 50%;

  .astronaut {
    position: absolute;
    right: 20%;
    /* top: 30%; */
    margin-top: -50px;
  }

  .h1 {
    font-size: 45px;
    line-height: 54px;
  }
  .ant-btn-secondary {
    background-color: #0E1014;
    color: #fff;
    border: 0;
    &:hover {
      background-color: #FFD12D;
      color: #0E1014;
    }
    &:disabled {
      background-color: #cfebfa;
      color: #0E1014;
    }
  }
}

@media (min-width: 2000px) {

}
@media (max-width: 992px) {

}
@media (max-width: 768px) {

}

@media (max-width: 540px) {
  .h1 {
    font-size: 40px;
    line-height: 48px;
  }
  .h2 {
    font-size: 26px;
    line-height: 30px;
  }
}
</style>
