<template>
  <div class="d-flex text-center flex-wrap mt-2 mt-md-0 pt-4">
    <span v-for="(item, idx) of structure.list" :key="'partner' + idx"
      class="d-flex flex-column justify-content-center align-items-center col-4 col-md-2 mt-2 mb-3">
      <img :src=item.src class="point-48" />
      <small class="fw-bold mt-2">{{ item.text }}</small>
    </span>
  </div>
</template>

<script>
import { h } from 'vue'
import { Button, Layout, Menu, Drawer, Modal } from 'ant-design-vue'

export default {
  name: 'Partners',
  components: {
  },
  data() {
    return {

    };
  },
  async mounted() {
  },
  methods: {
  },
  computed: {
    structure() {
      return {
        list: [
          { src: require('../assets/backed/img-redpoint.png'), text: 'Redpoint.' },
          { src: require('../assets/backed/img-kucoin.png'), text: 'KuCoin Ventures' },
          { src: require('../assets/backed/img-sky9.png'), text: 'Sky9 Capital' },
                    { src: require('../assets/backed/img-alchemy.png'), text: 'Alchemy Pay' },
          { src: require( '../assets/backed/img-element.png'), text: 'Element' },
          { src: require('../assets/backed/img-avalanche.png'), text: 'Avalanche Ventures' },
      { src: require('../assets/backed/img-uphonest.png'), text: 'UpHonest Capital' },
          { src: require('../assets/backed/img-huobi.png'), text: 'Huobi Ventures' },
          { src: require('../assets/backed/img-ym.png'), text: 'YM Capital' },
          { src: require('../assets/backed/img-cyberconnect.png'), text: 'CyberConnect' },
          { src: require('../assets/backed/img-mirana.png'), text: 'Mirana Ventures' },
          { src: require('../assets/backed/img-fenbushi.png'), text: 'Fenbushi Capital' },
          { src: require('../assets/backed/img-cobo.png'), text: 'Cobo Ventures' },
          { src: require('../assets/backed/img-bitcoke.png'), text: 'Bitcoke Ventures' },
          { src: require('../assets/backed/img-antalpha.png'), text: 'Antalpha Capital' },
          { src: require('../assets/backed/img-redline.png'), text: 'Redline DAO' },
          { src: require('../assets/backed/img-bybit.png'), text: 'Bybit NFT' },
          { src: require('../assets/backed/img-okx.png'), text: 'OKX NFT' },
          { src: require('../assets/backed/img-lingfeng.png'), text: 'Lingfengcap' },
          { src: require('../assets/backed/img-oneboat.png'), text: 'Oneboat Capital' },
          { src: require('../assets/backed/img-mexc.png'), text: 'MEXC Ventures' },
          { src: require('../assets/backed/img-linkvc.png'), text: 'LinkVC' },
          { src: require('../assets/backed/img-7updao.png'), text: '7upDAO' },
          { src: require('../assets/backed/img-ngc.png'), text: 'NGC Ventures' },
          { src: require('../assets/backed/img-link3.png'), text: 'Link3' },
          { src: require('../assets/backed/img-gate.png'), text: 'Gate labs' },
          { src: require('../assets/backed/img-snz.png'), text: 'SNZ' },
          { src: require('../assets/backed/img-viabtc.png'), text: 'Viabtc Capital' },
          { src: require('../assets/backed/img-metastone.png'), text: 'Meta Stone' },
          // { src: require('../assets/backed/img-nstone.png'), text: 'NStone' },
          { src: require('../assets/backed/img-sfcapital.png'), text: 'SFCapital' },
          // { src: require('../assets/backed/img-digifinex.png'), text: 'digifinex' },
          // { src: require('../assets/backed/img-opensea.png'), text: 'OpenSea' },
        ]
      }
    },
  }
}
</script>

<style lang="less">
</style>