<template>
      <!-- <a-layout-content class="container-fluid d-flex flex-column p-0" v-if="true">

    name {{ ixd.mintPasscard.name.view }}<br/>
    totalSupply {{ ixd.mintPasscard.totalSupply.view }}<br/>
    maxSupply {{ ixd.mintPasscard.maxSupply.view }}<br/>
    symbol {{ ixd.mintPasscard.symbol.view }}<br/>

    whiteListMintTotalSupply {{ ixd.mintPasscard.whiteListMintTotalSupply.view }}<br/>
    whiteListMintedVolume {{ ixd.mintPasscard.whiteListMintedVolume.view }}<br/>
    whiteListMintPrice {{ ixd.mintPasscard.whiteListMintPrice.view }}<br/>
    whiteListMintBegin {{ ixd.mintPasscard.whiteListMintBegin.view }}<br/>
    ---  ---<br/>
    findRefund {{ ixd.mintPasscard.holdTokenInfo.map(item => `${item.value} `) }} <br/>
    isWhiteListAddress {{ ixd.mintPasscard.isWhiteListAddress.value }} <br />
    ------<br/>
    address {{ $store.wallet.address.handled }}<br/>
    walletMintedVolume {{ ixd.mintPasscard.walletMintedVolume.view }}<br/>
    walletBalanceOf {{ ixd.mintPasscard.walletBalanceOf.handled }}<br/>
    address isValidated {{ $store.wallet.isValidated}}<br/>

    .whiteListMintTotalSupply {{ $store.nftSwaps.mintPasscard.whiteListMintTotalSupply.handled }} <br/>
    whiteListMintedVolume {{ $store.nftSwaps.mintPasscard.whiteListMintedVolume.handled }}<br/>


    ------<br/>
    whiteListSearchAddress {{ whiteListSearchAddress }}<br/>


</a-layout-content> -->


  <a-layout-content class="container-fluid d-flex flex-column p-0 cover"
    :data-maxsupply="ixd.mintPasscard.maxSupply.value"
    :data-totalsupply="ixd.mintPasscard.totalSupply.value"
    >

    <div class="container-lg d-flex flex-wrap align-items-center px-4 px-md-0">
      <div class="passcard order-1 order-md-12 col-12 col-md-6 d-flex justify-content-center justify-content-md-end px-0 px-md-4 pb-4 pb-md-0">
        <div>
          <img src="../assets/mint/passcard.png" class="mx-0 mx-md-4" />
          <!-- <div class="d-flex flex-column align-items-center justify-content-center">
            <span>.NFT</span>
            <small>Mint</small>
          </div> -->
        </div>
      </div>
      <div class="d-flex order-12 order-md-1 flex-column col-12 col-md-6 mb-4">
        <span class="h1 d-flex flex-column fw-bold pb-3 text-color-blank">
          TwitterScan Pass
        </span>
        <span class="h5 col-12 col-md-10 col-lg-12">
          <h5 class="mb-3 text-color-blank">
            Buy PASS now to get the premium membership of TwitterScan and unlock special priviledges.
          </h5>
          <span class="d-flex mb-3">
            <a class="d-flex align-items-center justify-content-center point-36 bg-dust me-2" :href=$store.nftSwaps.mintPasscard.etherscanUrl target="_blank" title="Etherscan"><iEtherscan /></a>
            <!-- <a class="d-flex align-items-center justify-content-center point-36 bg-dust me-2" :href=$store.nftSwaps.mintPasscard.openseaUrl target="_blank" title="Opensea"><iOpensea /></a> -->
            <a class="d-flex align-items-center justify-content-center point-36 bg-dust me-2" :href=$store.nftSwaps.mintPasscard.elementUrl target="_blank" title="Element"><iMintElement /></a>
            <a class="d-flex align-items-center justify-content-center point-36 bg-dust me-2" :href=$store.nftSwaps.mintPasscard.x2y2Url target="_blank" title="X2Y2"><iMintX2y2 /></a>
            <!-- <a class="d-flex align-items-center justify-content-center point-36 bg-dust" :href=$store.nftSwaps.mintPasscard.okxUrl target="_blank" title="OKX"><iMintOkx /></a> -->
          </span>
        </span>
        <ul class="timeline user-select d-flex col-12 col-md-11 col-lg-10 col-xl-9">
          <li @click="onTabTimeline('whitelist')" class="col fw-bold" :class="{hover: $store.nftSwaps.mintPasscard.tabTimelineIdx === 0}">
            <span>WHITELIST MINT</span>
            Sold Out
          </li>
          <li @click="onTabTimeline('public')" class="col fw-bold" :class="{hover: $store.nftSwaps.mintPasscard.tabTimelineIdx === 1}">
            <span>PUBLIC MINT</span>
            Sold Out
          </li>
        </ul>
        <!-- <div class="mint-input d-flex col-12 col-md-11 col-lg-10 col-xl-9">
          <busy class="d-flex col" size="28" :busying="ixd.mint.inputBusy">
            <div class="d-flex flex-column px-3 price col-7">
              <small>TOTAL PRICE</small>
              <span class="d-flex align-items-center fw-bold">{{ ixd.mint.price }}<small class="ps-2 fw-normal">ETH</small></span>
            </div>
            <div class="d-flex volumn col-5  px-3 user-select">
              <label class="minus" @click=$store.nftSwaps.mintPasscard.onDecline>—</label>
              <span class="text-center col">{{ $store.nftSwaps.mintPasscard.mintVolume.value }}</span>
              <label class="plus" @click=$store.nftSwaps.mintPasscard.onIncrease>+</label>
            </div>
          </busy>
        </div> -->
        <div class="mint-button col-12 col-md-11 col-lg-10 col-xl-9">
          <busy :busying=ixd.mint.busy class="mt-4 col-12 col-md-7">
            <a-button :disabled="($store.nftSwaps.mintPasscard.tabTimelineIdx === 0 ? !timer.isFinish : !publicTimer.isFinish) || ixd.mint.disabled" size="large" type="secondary" @click=$store.nftSwaps.mintPasscard.onMint class="d-flex align-items-center justify-content-center fw-bold col-12">
              {{ ixd.mint.text }}
            </a-button>
          </busy>
          <div class="d-flex justify-content-between px-3">
            <busy :busying=ixd.mint.whiteListMintTsBusy size="14" class="p-0">
              <small class="fw-bold">TIME LEFT<span class="ps-2">{{ $store.nftSwaps.mintPasscard.tabTimelineIdx === 0 ? countDown : publicCountDown }}</span></small>
            </busy>
            <busy :busying=ixd.mint.whiteListMintedVolumeBusy size="14" class="p-0">
              <small class="fw-bold">MAX 10,000
                <!-- <span class="ps-2">{{ ixd.mint.mintedVolume }}</span> / {{ ixd.mint.supply }} -->
              </small>
            </busy>
          </div>
        </div>
        <div v-if="$store.nftSwaps.mintPasscard.tabTimelineIdx === 1" class="buyback pt-3 col-12 col-md-11 col-lg-10 col-xl-9">
          <span v-if="ixd.mintPasscard.isWhiteListAddress.value && +ixd.mintPasscard.walletMintedVolume.value < 2" class="mb-2">
            You are a whitelist re-issue user, you are able to purchase 2 PASS at the price of 0.08eth each.
          </span>
          <h4>
            <iMintArrorwRight class="me-1" /><span @click="onPopupRefund">Fixed-price Buyback</span>
          </h4>

          <a-modal
            v-model:visible="popupShow"
            title="Please select the Refund Program you want to participate"
            centered
            :footer=popupFooter
            @ok="popupShow = false"
          >
            <div class="refundBox d-flex flex-column">
              <span class="hold mb-3">
                Hold TSP:
                <busy class="" size="20" :busying="$store.nftSwaps.mintPasscard.findRefundBusy"></busy>
                <span class="tt" v-for="item in $store.nftSwaps.mintPasscard.tempHoldTokenInfo" :key="item">#{{ item }}</span>
              </span>
              <!-- {{ $store.nftSwaps.mintPasscard.walletBalanceOf.view }}
              {{ $store.nftSwaps.mintPasscard.refundExNftIdList.map(item => item.value) }}
              {{ $store.nftSwaps.mintPasscard.refundPublicNftIdList.map(item => item.value) }} -->
              <a-radio-group v-model:value="selectEx" :options="plainOptions" />
              <span class="fw-bold py-3">Please select the PASS your wish to claim a refund for</span>
              <busy class="d-flex col" size="28" :busying="$store.nftSwaps.mintPasscard.findRefundBusy">
                <template v-if="selectEx === 'ex'">
                  <a-radio-group v-if="$store.nftSwaps.mintPasscard.refundExNftIdList.length > 0" v-model:value="$store.nftSwaps.exExRefund.selectExRefundId" :options="$store.nftSwaps.mintPasscard.refundExNftIdList" />
                  <span v-else class="tip my-3">No eligible NFTs</span>
                </template>
                <template v-if="selectEx === 'public'">
                  <a-radio-group v-if="$store.nftSwaps.mintPasscard.refundPublicNftIdList.length > 0" v-model:value="$store.nftSwaps.mintPasscard.selectPublicRefundId" :options="$store.nftSwaps.mintPasscard.refundPublicNftIdList" />
                  <span v-else class="tip my-3">No eligible NFTs</span>
                </template>
              </busy>
              <small class="mt-4" v-if="selectEx === 'ex'">*If you are successfully buy the TwitterScan PASS on OKX NFT/BYBIT NFT at 0.15ETH on Oct. 2nd, you can return the PASS and get refund in 0.15ETH.</small>
              <small class="mt-4" v-if="selectEx === 'public'">*If you successfully mint the PASS on TwitterScan Public sale on Oct.3rd at 0.15ETH, you can return the PASS and get 0.15ETH refund.</small>
              <small class="mt-1">*Refund pre-requirement: there are no listing or transfer behaviors after purchase the PASS.</small>
              <small class="mt-1">*After using the return program and submitted your request, your NFT PASS will be returned and the refund will be transferred to your address.</small>
              <small class="mt-1">*The refund program will be terminated on Oct. 8th, 2022 at 3:00 AM UTC, after this time, you are not able to use this program.</small>
              <busy :busying="popupRefundBusy" class="d-flex mt-4 col-12 ">
                <a-button v-if="selectEx === 'ex'" :disabled="popupRefundDisabled" type="secondary" @click="$store.nftSwaps.exExRefund.onLuan" class="d-flex align-items-center justify-content-center fw-bold col-12">
                  Approve & Confirm
                </a-button>
                <a-button v-else :disabled="popupRefundDisabled" type="secondary" @click="$store.nftSwaps.mintPasscard.onRefund" class="d-flex align-items-center justify-content-center fw-bold col-12">
                  Confirm
                </a-button>
              </busy>
            </div>
          </a-modal>
        </div>
      </div>
    </div>
  </a-layout-content>

  <a-layout-content class="whiteListBg container-fluid d-flex flex-column p-0">
    <div class="container-lg d-flex px-4 col-12">
      <div class="d-flex flex-column col-12 col-md-7 mb-4 mb-md-0">
        <h2 class="fw-bold text-center text-md-start col-12 col-md-9">WhiteList Query</h2>
        <div class="d-flex flex-column align-items-center align-items-md-start flex-lg-row pt-3 pe-md-5 pb-3">
          <span class="d-flex align-items-center justify-content-center fw-bold col-12 col-md-10 col-lg-7 my-1 me-2">
            <a-input allowClear size="large" v-model:value="whiteListSearchAddress" placeholder="Enter your wallet address" class="ps-4 query-input" />
          </span>
          <button-busy type="ghost" block :busying="findWhiteListBusy"  @click="onFindWhiteList" class="d-flex align-items-center justify-content-center fw-bold col-5 col-md-5 col-lg-3 my-1">
            Check
          </button-busy>
        </div>
        <div class="d-flex justify-content-center justify-content-md-start pb-4 mb-5 mb-md-0">
          <span v-if="findWhiteListTip === 1" class="d-flex align-items-center tip tip-success">
            <iMintCheckCircle class="me-2" />You are in the list
          </span>
          <span v-if="findWhiteListTip === 2" class="d-flex align-items-center tip tip-normal">
            <iMintUnhappy class="me-2" />You are not in the list
          </span>
          <span v-if="findWhiteListTip === 3" class="d-flex align-items-center tip tip-error">
            <iMintAlert class="me-2" />Please enter the correct address
          </span>
        </div>
      </div>
      <iMintFind class="d-none d-md-block" />
    </div>
  </a-layout-content>

  <a-layout-content class="benefitsBg container-fluid d-flex flex-column p-0">
    <div class="container-lg d-flex flex-column flex-lg-row px-4 col-12">
      <h2 class="d-flex fw-bold text-center text-lg-start col-12 col-lg-4">
        <span class="col-12 col-lg-9 py-3">Get Exclusive Benefits</span>
      </h2>
      <ul class="d-flex flex-wrap col-12 col-lg-8">
        <li class="d-flex col-12 col-md-6 py-3 pe-4">
          <span><iMintBird /></span>
          <span class="d-flex flex-column ms-3">
            <h4 class="fw-bold">Early Access</h4>
            <span>Get early access to upcoming TwitterScan web3 domains launch before anyone else has access to it.</span>
          </span>
        </li>
        <li class="d-flex col-12 col-md-6 py-3 pe-4">
          <span><iMintPremium /></span>
          <span class="d-flex flex-column ms-3">
            <h4 class="fw-bold">Premium Features</h4>
            <span>TwitterScan's premium product features</span>
          </span>
        </li>
        <li class="d-flex col-12 col-md-6 py-3 pe-4">
          <span><iMintWhitelist /></span>
          <span class="d-flex flex-column ms-3">
            <h4 class="fw-bold">Get whitelist</h4>
            <span>Entitled to get whitelist for projects raffled in TwitterScan</span>
          </span>
        </li>
        <li class="d-flex col-12 col-md-6 py-3 pe-4">
          <span><iMintBenefits /></span>
          <span class="d-flex flex-column ms-3">
            <h4 class="fw-bold">Other benefits</h4>
            <span>TwitterScan PASS holders are eligible to renew TwitterScan web3 domains name services with a 50% discount.</span>
          </span>
        </li>
      </ul>
    </div>
  </a-layout-content>

  <a-layout-content class="faqBg container-fluid d-flex flex-column p-0">
    <div class="container-lg d-flex flex-column align-items-center px-4 col-12">
      <h2 class="fw-bold text-center col-9">FAQ</h2>
      <ul class="d-flex flex-column col-12  col-md-10 col-lg-8 gap-2">
        <li v-for="(item, idx) of structure.faq" :key="'faq-' + idx" class="d-flex col-12" >
          <input type="checkbox" checked :id="'check_faq_' + idx" />
          <div class="d-flex col" :data-key="idx + 1">
            <div class="col pe-4">
              <h4 class="fw-bold mb-0">{{ item.title }}</h4>
              <span class="mt-2">{{ item.content }}</span>
            </div>
            <label :for="'check_faq_' + idx"></label>
          </div>
        </li>
      </ul>
    </div>
  </a-layout-content>

  <a-layout-content class="partnersBg container-fluid d-flex flex-column p-0">
    <div class="container-lg d-flex flex-column align-items-center px-4">
      <h2 class="d-flex flex-column fw-bold text-center col-9">
        <small>Backed by</small>
        The most innovate and creative minds
      </h2>
      <Partners />
    </div>
  </a-layout-content>

</template>

<script>
import Web3 from 'web3'
import axios from 'axios'
import BN from 'bignumber.js'
import { Layout, Button, Popover, Tabs, Input, message, Modal, Radio } from 'ant-design-vue'
import Busy from '../components/busy'
import ButtonBusy from '../components/button-busy'
import Partners from '../components/Partners'
import { parseAntComponent } from '../utils/helpers'
import { sleep, now } from '../utils'
import { iArtAmazingRocketExample, iOpensea, iEtherscan,
  iMintBenefits,
  iMintBird,
  iMintPremium,
  iMintWhitelist,
  iMintFind,
  iMintAlert,
  iMintCheckCircle,
  iMintUnhappy,
  iMintArrorwRight,
  iMintElement,
  iMintX2y2,
  iMintOkx
} from '../components/icons'

export default {
  components: {
    ...parseAntComponent([Layout.Content, Button, Popover, Input, Input.Group, Modal, Radio, Radio.Group]),
    Busy,
    ButtonBusy,
    iArtAmazingRocketExample,
    iOpensea,
    iEtherscan,
    iMintBenefits,
    iMintBird,
    iMintPremium,
    iMintWhitelist,
    iMintFind,
    iMintAlert,
    iMintCheckCircle,
    iMintUnhappy,
    iMintArrorwRight,
    iMintElement,
    iMintX2y2,
    iMintOkx,
    Partners
  },
  data() {
    return {
      specialSignCode: '',

      findWhiteListBusy: false,
      findWhiteListTip: 0,
      whiteListSearchAddress: '',
      timer: {
        end: 0, // 结束的时间戳
        now: 0, // 当前时间戳
        residual: -1, // 距离的时间戳
        isFinish: false
      },
      publicTimer: {
        end: 0, // 结束的时间戳
        now: 0, // 当前时间戳
        residual: -1, // 距离的时间戳
        isFinish: false
      },

      popupShow: false,
      popupFooter: null,

      plainOptions: [
        { label: 'Refund of PASS Purchased at Exchange', value: 'ex' },
        { label: 'Refund of PASS Purchased in TwitterScan Public Sale', value: 'public' }
      ],
      selectEx: 'ex',
    }
  },
  async mounted (e) {
    this.timerLoop()
    this.publicTimerLoop()

    await this.$store.nftSwaps.mintPasscard.getConf()
  },
  methods: {
    timerLoop () {
      setTimeout(() => {
        const { value } = this.$store.nftSwaps.mintPasscard.whiteListMintBegin
        let { timer } = this

        if (value > 0) {
          if ( timer.end <= 0){
            timer.end = this.timer.end = value
            this.timer.residual = timer.end - ~~(now() / 1000)
          }

          if (!this.timer.isFinish ) {
            // 计算
            this.timer.residual = Math.max(this.timer.residual - 1, 0)
            this.timer.isFinish = this.timer.residual === 0
          }
        }

        !this.timer.isFinish
          && this.timerLoop()
      }, 1000)
    },

    publicTimerLoop () {
      setTimeout(() => {
        // TEST:
        const { value } = this.$store.nftSwaps.mintPasscard.publicMintMintBegin
        let {  publicTimer } = this

        if (value > 0) {
          if ( publicTimer.end <= 0){
            publicTimer.end = this.publicTimer.end = value
            this.publicTimer.residual = publicTimer.end - ~~(now() / 1000)
          }

          if (!this.publicTimer.isFinish ) {
            // 计算
            this.publicTimer.residual = Math.max(this.publicTimer.residual - 1, 0)
            this.publicTimer.isFinish = this.publicTimer.residual === 0
          }
        }

        !this.publicTimer.isFinish
          && this.publicTimerLoop()
      }, 1000)
    },

    async onFindWhiteList() {
      const { nftSwaps, wallet } = this.$store
      let address = this.whiteListSearchAddress

      this.findWhiteListBusy = true
      this.findWhiteListTip = 0
      try {
        address = Web3.utils.toChecksumAddress(address)
        if (Web3.utils.isAddress(address)) {
          const checkcode = await nftSwaps.mintPasscard.whiteListCheck(address)

          this.findWhiteListTip = checkcode.length > 0 ? 1 : 2
        } else {
          this.findWhiteListTip = 3
        }
      } catch(e) {
        this.findWhiteListTip = 3
      }

      this.findWhiteListBusy = false

      // 自动消失
      // await sleep(2000)
      // this.findWhiteListTip = 0
    },
    onCountDown(residual) {
      const result = {
        house: 0,
        houseView: '--',
        minute: 0,
        minuteView: '--',
        second: 0,
        secondView: '--'
      }
      const fillZero = (num) => {
        let result = num + ''

        return result.length === 1
          ? Array(1).fill(0).join('') + result
          : result
      }

      if (residual > -1) {
        result.house = ~~(residual / 3600)
        result.houseView = fillZero(result.house)

        const minuteR =  residual - 3600 * result.house
        result.minute = ~~(minuteR / 60)
        result.minuteView = fillZero(result.minute)

        result.second = minuteR - 60 * result.minute
        result.secondView = fillZero(result.second)
      }

      return `${result.houseView} : ${result.minuteView} : ${result.secondView}`
    },
    onTabTimeline(type) {
      // switch(type) {
      //   case 'whitelist':
      //     this.$store.nftSwaps.mintPasscard.tabTimelineIdx = 0
      //     break;
      //   case 'public':
      //     this.$store.nftSwaps.mintPasscard.tabTimelineIdx = 1
      //     break;
      //   default:
      // }
      // // update volume
      // this.$store.nftSwaps.mintPasscard.onRefresh()
    },
    async syncData () {
      const { nftSwaps: { mintPasscard } } = this.$store

      // TODO: 目前不要 await
      mintPasscard.walletMintedVolume.referrer()

      await mintPasscard.onRefresh()
    },

    async onPopupRefund() {
      const { nftSwaps: { mintPasscard, exExRefund } } = this.$store

      this.popupShow = true

      // 初始重置
      exExRefund.selectExRefundId = ''
      mintPasscard.selectPublicRefundId = ''

      await mintPasscard.findRefund()
    }
  },
  computed: {
    countDown () {
      return this.onCountDown(this.timer.residual)
    },
    publicCountDown () {
      return this.onCountDown(this.publicTimer.residual)
    },

    popupRefundDisabled() {
      const { mintPasscard, exExRefund } = this.$store.nftSwaps
      let result = true

      switch(this.selectEx) {
        case 'ex':
          result = !exExRefund.selectExRefundId
          break;
        case 'public':
          result = !mintPasscard.selectPublicRefundId
          break;
        default:
      }

      return result
    },
    popupRefundBusy () {
      const { mintPasscard, exExRefund } = this.$store.nftSwaps
      let result = false

      switch(this.selectEx) {
        case 'ex':
          // approve  || mintPasscard.state.busy
          result = mintPasscard.findRefundBusy || exExRefund.state.busy 
          break;
        case 'public':
          result = mintPasscard.findRefundBusy || mintPasscard.state.busy
          break;
        default:
      }

      return result
    },

    // 结构性
    structure() {
      return {
        faq: [
          { title: 'What is the total supply of the TwitterScan PASS?', content: '10,000' },
          { title: 'What is the maximum mint amount for each eligible address?', content: 'Each wallet is permitted to mint a maximum of 2 PASSes'},
          { title: 'How long would the PASS be valid, will it expire?', content: 'TwitterScan PASS is a lifetime access PASS.' },
          { title: 'Can I trade TwitterScan PASS on the secondary market? How do PASS royalties work?', content: 'PASSes can be traded on trading platforms such as opensea, and a 7.5% royalty will be charged when trading happens.' },
          { title: 'Can I use the TwitterScan platform without buying an NFT?', content: 'You can use TwitterScan for free with many features, although limited compared to PASS holders.' },
          { title: "Does holding a PASS ensure my registration on all TwitterScan partners' whitelists?", content: 'The PASS holders would be given higher priority in TwitterScan’s partnered WL raffles.' },
          { title: "How many TwitterScan web3 domains can I register with one PASS?", content: 'You are able to register multiple domains, acquiring a PASS allows you to register one 3-Digit (000-999) and one 4-Digit (0000-9999) domain first.' },
          { title: "Can I register TwitterScan web3 domains if I don't have a PASS?", content: "Yes, you can still register multiple desired domain names. But you are not eligible to register 3-Digit (000-999) and 4-Digit (0000-9999) domains if you don't have PASS." },
        ]
      }
    },

    ixd() {
      const { nftSwaps: { mintPasscard, exExRefund }, wallet } = this.$store
      let lastLinkWalletAddress = localStorage.getItem('lastLinkWalletAddress-a')
console.log(wallet.address.handled , lastLinkWalletAddress, wallet.address.handled !== lastLinkWalletAddress)
      if (wallet.address.handled !== lastLinkWalletAddress) {
        if (wallet.address.handled) {
          localStorage.setItem('lastLinkWalletAddress-a', wallet.address.handled)
          // sync
          // NOTE: 因为默认 whitelist
          this.syncData()

          // 初始重置
          exExRefund.selectExRefundId = ''
          mintPasscard.selectPublicRefundId = ''

          if (this.popupShow) {
            mintPasscard.findRefund()
          }
        } else {
          localStorage.removeItem('lastLinkWalletAddress-a')
        }
      }

      if (+mintPasscard.maxSupply.value === 0 && +mintPasscard.totalSupply.value ) {
        mintPasscard.onRefresh()
      }

      const mint = mintPasscard.tabTimelineIdx === 0
        ? {
            busy: mintPasscard.state.busy,
            disabled:
            (+mintPasscard.whiteListMintTotalSupply.value - +mintPasscard.whiteListMintedVolume.value) <= 0 || !(mintPasscard.mintVolume.value > 0) || !wallet.isValidated || !mintPasscard.isWhiteListAddress.value,
            minted: mintPasscard.walletMintedVolume.handled > 0,
            text: wallet.isValidated
              ? !mintPasscard.walletMintedVolume.state.busy
                ? +mintPasscard.whiteListMintTotalSupply.handled > +mintPasscard.whiteListMintedVolume.handled
                  ? mintPasscard.isWhiteListAddress.value
                    ? 'Mint'
                    : 'Not in the whitelist'
                  : 'Sold Out'
                : 'Eligibility Check...'
              : 'No wallet connected',
            mintedVolume: mintPasscard.whiteListMintedVolume.view,
            supply: mintPasscard.whiteListMintTotalSupply.view,
            price: mintPasscard.whiteListMintPrice.handled * mintPasscard.mintVolume.value,
            inputBusy: mintPasscard.walletMintedVolume.state.busy || mintPasscard.whiteListMintPrice.state.loading,
            whiteListMintTsBusy: mintPasscard.whiteListMintBegin.state.loading,
            whiteListMintedVolumeBusy: mintPasscard.whiteListMintedVolume.state.loading
          }
        : mintPasscard.isWhiteListAddress.value && !(+mintPasscard.walletMintedVolume.value >= 2)
          ? {
              busy: mintPasscard.state.busy,
              // disabled: +mintPasscard.maxSupply.value <= +mintPasscard.totalSupply.value || !(mintPasscard.mintVolume.value > 0) || !wallet.isValidated,
              disabled: true,
              minted: mintPasscard.walletMintedVolume.handled > 0,
              // text: wallet.isValidated
              //  ? !mintPasscard.walletMintedVolume.state.busy
              //    ? +mintPasscard.totalSupply.value < +mintPasscard.maxSupply.value
              //      ? mintPasscard.isWhiteListAddress.value
              //        ? 'Mint'
              //        : 'Not in the whitelist'
              //      : 'Sold Out'
              //    : 'Eligibility Check...'
              //  : 'No wallet connected',
              text: 'Sold Out',
              // mintedVolume: mintPasscard.whiteListMintedVolume.view,
              mintedVolume: +mintPasscard.maxSupply.value > 0
                ? Math.abs(+mintPasscard.maxSupply.value - +mintPasscard.totalSupply.value - 3500)
                : '0',
              // supply: mintPasscard.whiteListMintTotalSupply.view,
              supply: '3500',
              price: BN(mintPasscard.whiteListMintPrice.handled).times(mintPasscard.mintVolume.value).toString(),
              inputBusy: mintPasscard.walletMintedVolume.state.busy || mintPasscard.whiteListMintPrice.state.loading,
              whiteListMintTsBusy: mintPasscard.whiteListMintBegin.state.loading,
              whiteListMintedVolumeBusy: mintPasscard.whiteListMintedVolume.state.loading
            }
          : {
            busy: mintPasscard.state.busy,
            // disabled: +mintPasscard.maxSupply.value <= +mintPasscard.totalSupply.value || !(mintPasscard.mintVolume.value > 0) || !wallet.isValidated,
            disabled: true,
            minted: mintPasscard.walletPublicMintedVolume.handled > 0,
            // text: wallet.isValidated
               //  ? !(+mintPasscard.totalSupply.value < +mintPasscard.maxSupply.value)
                  // ? 'Sold Out'
                 // : 'Mint'
              // : 'No wallet connected',
            text: 'Sold Out',
            // totalsupply 已mint量- 白名单已mint 量 = public 已mint 量
            // mintedVolume: +mintPasscard.totalSupply.value - +mintPasscard.whiteListMintedVolume.value,
            // 最大供应 - 已 mint 量
             mintedVolume: +mintPasscard.maxSupply.value > 0
              ? Math.abs(+mintPasscard.maxSupply.value - +mintPasscard.totalSupply.value - 3500)
              : '0',
            // 最大可mint量-白名单量 = public 量
            // supply: +mintPasscard.maxSupply.value - +mintPasscard.whiteListMintTotalSupply.value,
            supply: '3500',
            price: BN(mintPasscard.publicMintMintPrice.handled).times(mintPasscard.mintVolume.value).toString(),
            inputBusy: mintPasscard.walletMintedVolume.state.busy || mintPasscard.walletPublicMintedVolume.state.busy || mintPasscard.publicMintMintPrice.state.loading,
            whiteListMintTsBusy: mintPasscard.publicMintMintBegin.state.loading,
            whiteListMintedVolumeBusy:  mintPasscard.whiteListMintedVolume.state.loading
          }
      // const mint = {
      //   busy: false,
      //   disabled: true,
      //   minted: mintPasscard.walletBalanceOf.handled > 0,
      //   text: 'Mint',
      //   mintedVolume: '0',
      //   supply: '5,000',
      //   price: '0.08',
      //   inputBusy: false,
      //   whiteListMintTsBusy: false,
      //   whiteListMintedVolumeBusy: false
      // }

      return {
        mintPasscard: mintPasscard,
        wallet,
        mint,
      }
    },
  },
}
</script>

<style scoped lang="less">
.cover {
  background: url('../assets/mint/coverBg.png') repeat-x center bottom,
  linear-gradient(180deg, #12131A 0%, #1F3399 65.1%, #24A4B3 100%);

  border-bottom: 1px solid #000000;
  background-size:contain;

  > div {
    padding-top: 100px;

    .h1 {
      font-size: 56px;
      line-height: 64px;
    }

    h5 {
      color: rgba(255, 255, 255, 0.8);
      line-height: 22px;
    }

    .passcard {
      margin-bottom: -100px;
      > div {
        position: relative;
        > div {
          margin-top: -52%;
          position: relative;
          margin-left: 66%;
          margin-bottom: 138px;
          width: 112px;
          height: 112px;
          background: #FFFFFF;
          border: 1px solid #000000;
          box-shadow: 0px 4px 20px -8px rgba(0, 0, 0, 0.15);
          border-radius: 32px;
          > span {
            font-weight: 700;
            font-size: 32px;
            line-height: 44px;
            color: #29BDCC;
          }
          > small {
            background: #29BDCC;
            border-radius: 3px;
            color: #fff;
            padding: 0 4px;
          }
        }
      }
    }

    .timeline {
      border-radius: 18px 18px 0 0;
      overflow: hidden;
      font-size: 12px;
      line-height: 16px;
      border-top:  1px solid #000000;
      border-left:  1px solid #000000;
      border-right:  1px solid #000000;
      li {
        display: flex;
        position: relative;
        flex-direction: column;
        opacity: 0.45;
        background: #FFFFFF;
        padding: 12px 16px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        span {
          color: #209BAA;
        }
        &:first-child {
          border-right:  1px solid #000000;
        }
        // &:hover {
        //   opacity: 0.85;
        //   cursor: pointer;
        // }
      }

      .hover {
        opacity: 1;
        &::after {
          content: '';
          display: block;
          background: #EBFAFA;
          border: 4px solid #29BDCC;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          position: absolute;
          right: 12px;
        }
        &:hover {
          opacity: 1;
          cursor: default;
        }
      }
    }

    .mint-input {
      height: 80px;
      background: #FFFFFF;
      border: 1px solid #000000;
      overflow: hidden;
      > small {
        color: #209BAA;
      }

      .price {
        padding-top: 12px;
        padding-bottom: 12px;
        > span {
          font-size: 28px;
          line-height: 40px;
        }
      }
      .volumn {
        padding-top: 30px;
        padding-bottom: 12px;
        background: #F2EEE7;
        label {
          display: flex;
          width: 36px;
          height: 36px;
          background: linear-gradient(270deg, #F2EEE7 90.32%, rgba(242, 238, 231, 0) 100%);
          border: 1px solid #0E1014;
          border-radius: 50%;
          cursor: pointer;
          font-size: 20px;
          justify-content:center;
          &:hover {
            background: rgba(14, 16, 20, 0.11);
          }
          &.minus {
            font-size: 23px;
            font-weight: bold;
          }
          &.plus {
            font-size: 32px;
            line-height: 30px;
          }
        }
        > span {
          font-size: 28px;
        }
      }
    }

    .mint-button {
      // height: 80px;
      background: linear-gradient(90deg, #91EDF2 0%, #99AAFF 47.92%, #C5ADFF 83.8%, #FFADFF 100%);

      border-right: 1px solid #000000;
      border-bottom: 1px solid #000000;
      border-left: 1px solid #000000;
      border-radius: 0 0 18px 18px;

      .ant-btn-secondary {
        color: #fff;
        // height: 56px;
        height: 112px;
        background: #000000;
        border: 1px solid #000000;
        border-radius: 0;
        font-size: 20px;
        line-height: 28px;

        &:hover {
          background-color: #fff;
          color: #000;
        }
        &:disabled {
          color: rgba(255, 255, 255, 0.35);
          &:hover {
            color: rgba(255, 255, 255, 0.35);
            background: #000000;
            border: 1px solid #000000;
          }
        }
      }
    }

    .buyback {
      display: flex;
      flex-direction: column;
      h4 {
        cursor: pointer;
        // background: linear-gradient(90deg, #99FAFF 0%, #DBCCFF 34.9%, #FFCCFF 67.71%, #FFFFFF 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        color: #fff;
        font-weight: bold;
        span {
          text-decoration-line: underline;
          &:hover {
            color: #fff;
            text-decoration-line: none;
          }
        }
      }
      > span {
        display: flex;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.7);
        padding: 12px 16px;
        border: 1px solid #000000;
        border-radius: 16px;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
        ul {
          li::before {
            content: '·';
            padding: 0 10px;
          }
        }
      }
    }
  }
}


.whiteListBg {
  background: #29BDCC;
  border-bottom: 1px solid #000000;
  > div {
      padding: 48px 0 0;
      h2 {
        margin-top: 72px;
      }
  }
  .query-input {
    height: 42px;
  }

  .ant-input-affix-wrapper {
    &.ant-input-affix-wrapper-lg {
      background: #FFFFFF;
      border: 1px solid #000000;
      padding: 7px 16px;
    }
  }
  .ant-btn {
    &.ant-btn-ghost {
      background: #FFFFFF;
      border: 1px solid #000000;
      &:hover {
        color: #29BDCC;
      }
    }
  }

  .tip {
    padding: 5px 16px 5px 8px;
    opacity: 0.9;
    border: 1px solid #000000;
    border-radius: 99px;

    &.tip-success {
      background: linear-gradient(135deg, #4DCCD6 0%, #92EDF3 100%);
    }
    &.tip-normal {
      background: linear-gradient(135deg, #F2EEE7 0%, #FAF7F2 100%);
    }
    &.tip-error {
      background: linear-gradient(135deg, #FF6B66 0%, #FFBAB3 100%);
    }
  }
}

.benefitsBg {
  border-bottom: 1px solid #000000;
  > div {
      padding: 120px 0 104px;
      background: url('../assets/mint/benefits.png') no-repeat left bottom;
  }
}

.faqBg {
  background: #F2EEE7;
  border-bottom: 1px solid #000000;

  > div {
      padding: 120px 0 108px;
       > h4 {
    margin-bottom: 32px;
  }

  ul {
    li {
      label {
        display: flex;
        width: 28px;
        height: 28px;
        background: rgba(14, 16, 20, 0.05);
        border: 1px solid #0E1014;
        border-radius: 50%;
        cursor: pointer;
        font-size: 20px;
        justify-content:center;
        &::before {
          content: '-';
        }
        &:hover {
          background: rgba(14, 16, 20, 0.11);
        }
      }
      span {
        display: flex;
      }
      > div {
        padding: 12px 16px;
        border-radius: 16px;
        background: rgba(14, 16, 20, 0.05);

        &::before {
          display: flex;
          content: attr(data-key);
          width: 28px;
          height: 28px;
          background: #FFFFFF;
          border: 1px solid #0E1014;
          border-radius: 50%;
          font-size: 20px;
          line-height: 28px;
          overflow: hidden;
          margin-right: 16px;
          align-items: center;
          justify-content: center;
        }
      }

      input[type="checkbox"] {
        position: absolute;
        left: -9999px;
      }
      input[type="checkbox"]:checked~.d-flex {
        background-color: unset;
      }
      input[type="checkbox"]:checked~.d-flex > .col > span {
        display: none;
      }
      input[type="checkbox"]:checked~.d-flex > label::before {
        content: '+';
      }
    }
  }
  }
 
}

.partnersBg {
  border-bottom: 1px solid #000000;
  > div {
    padding: 120px 0;
  }
}


@media (min-width: 2000px) {

}
@media (max-width: 992px) {
  .benefitsBg {
    > div {
      padding-bottom: 220px;
      background-position-x: center;
    }
  }
}
@media (max-width: 768px) {

}

@media (max-width: 540px) {

}


.refundBox {
  .tip {
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    opacity: 0.6;
  }
  small {
    font-size: 10px;
    line-height: 14px;
    color: #000000;
    opacity: 0.5;
  }
  
  .hold {
    span.tt {
      padding: 2px 8px;
      background-color: #eee;
      border-radius: 4px;
      margin-right: 4px;
      margin-bottom: 4px;
      display: inline-block;
    }
  }

  .ant-btn-secondary {
    color: #fff;
    height: 42px;
    background: #000000;
    border: 1px solid #000000;
    border-radius: 42px;
    font-size: 14px;
    line-height: 28px;

    &:hover {
      background-color: #fff;
      color: #000;
    }
    &:disabled {
      color: rgba(255, 255, 255, 0.35);
      &:hover {
        color: rgba(255, 255, 255, 0.35);
        background: #000000;
        border: 1px solid #000000;
      }
    }
  }
}


</style>
<style lang="less">
.whiteListBg {
  .ant-btn {
    &.ant-btn-ghost {
      background: #FFFFFF;
      border: 1px solid #000000;
      &:hover {
        color: #29BDCC;
      }
    }
  }

  .ant-input-affix-wrapper {
    input[type="text"] {
      border-radius: 0;
    }
  }
}

footer.ant-layout-footer.container-fluid {
  background-color: #000 ;
  .text-color-dust {
    color: rgba(255, 255, 255, 0.7);
  }
}

.ant-modal-root {
  .ant-modal-content {
    background: #FFFFFF;

    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 12px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    .ant-modal-header {
      border-bottom: 0px;
    }
    .ant-modal-body {
      padding-top: 12px;
    }
    .ant-modal-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #17181A;
    }
  }
}
.ant-radio-group {
  &.ant-radio-group-default {
    display: flex;
    flex-direction: column;
    .ant-radio-wrapper {
      margin: 4px 0;
      color: rgba(23, 24, 26, 0.85);
      font-size: 14px;
      line-height: 20px;
      .ant-radio-checked .ant-radio-inner {
        border-color: #fff;
      }
      .ant-radio-inner{
        background-color: #fff;
        border-color: eee;
      }
      .ant-radio-checked {
        .ant-radio-inner{
          background-color: #000000;
          &::after  {
            background-color:#fff;
            width: 6px;
            height: 6px;
            left: 4px;
            top: 4px;
          }
        }
      }
    }
  }
}

</style>