<template>
  <span>
    <a-spin :indicator="indicator" :spinning="busying">
      <slot></slot>
    </a-spin>
  </span>
</template>

<script>
import { Spin } from 'ant-design-vue'
import { parseAntComponent } from '../utils/helpers'
import { LoadingOutlined } from '@ant-design/icons-vue';

export default {
  name: 'busy',
  props: {
    busying: Boolean,
    size: {
      type: String,
      default: '18'
    }
  },
  components: {
    ...parseAntComponent([Spin]),
    LoadingOutlined,
  },
  data() {
    const style = `font-size: ${this.size}px`

    return {
      indicator: <LoadingOutlined style={style} spin />,
    };
  },
};
</script>