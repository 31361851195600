/** @format */

import { reactive } from 'vue'
import BN from 'bignumber.js'

import { ERC20 } from './helpers/abi'
import { getDotenvAddress } from '../store/helpers/methods'
import { TOKEN_MIN_AMOUNT_ETHER, TOKEN_MAX_AMOUNT_ETHER, TOKEN_INFINITE_MIN_AMOUNT_ETHER } from './helpers/constant'

import ModelValueEther from './value/ether'
import ModelValueInput from './value/input'
import ModelValueWallet from './value/wallet'
import ModelValuePrice from './value/price'
import ModelValueString from './value/string'
import ModelValueBytes32 from './value/bytes32'
import ModelValueUint8 from './value/uint8'
import ModelValueError from './base/error'
import ModelState from './base/state'

import storeWallet from '../store/wallet'
import swaps from '../store/swaps'

import i18n from '../i18n'
import notify from '../store/notify'

import { floor } from '../utils'
import ModelValueAddress from './value/address'
import { listenEvent } from '../store/helpers/methods'

export default {
  /**
   * @param {Object} opts
   * @param {string} opts.code 内部 token code
   * @param {string=} opts.address 地址，address 选其一
   * @param {string=} opts.dotenvAddressName 使用 getDotenvAddress() 规则来获取，address 选其一，会替换 address
   * @param {Array=} opts.abi
   * @param {string=} opts.icon 缺省与 code 相同
   * @param {Object=} opts.customAssociatedTokenModel 追加 associatedToken 数据集的单元 Modal
   * @param {string=} opts.acquisitionUrl 获取该币种的 url
   * @param {number=} opts.viewDecimal 显示内容的显示精度
   * @param {Function=} opts.viewMethod 显示内容的舍入方法
   * @param {Object} opts.stateParams 状态参数
   *
   * @param {boolean=} opts.isInfiniteAllowance
   * @param {string=} opts.symbolMethodName
   * @param {string=} opts.balanceOfMethodName
   * @param {string=} opts.totalSupplyMethodName
   * @return {!Object}
   */
  create({
    code = '',
    address = '',
    dotenvAddressName = '',
    abi = ERC20,
    icon = '',

    customAssociatedTokenModel = () => ({}),
    acquisitionUrl = '',
    viewDecimal = 0,
    viewMethod = floor,

    stateParams = {},

    isInfiniteAllowance = false,
    // ERC20
    nameMethodName = 'name',
    symbolMethodName = 'symbol',
    decimalsMethodName = 'decimals',
    baseURIMethodName = 'baseURI',
    balanceOfMethodName = 'balanceOf',
    approveMethodName = 'approve',
    allowanceMethodName = 'allowance',
    maxSupplyMethodName = 'maxSupply',
    totalSupplyMethodName = 'totalSupply',
    transferFromMethodName = 'transferFrom',
    transferMethodName = 'transfer',
  } = {}) {
    const __default__ = {
      contract: null,
    }
    const __store__ = {
      isCcontractBase: false,
      isContractWallet: false,
      contract: null,
    }
    const __cache__ = {
      networkId: undefined,
      decimals: undefined,
      precision: undefined,
    }
    /**
     * 参数接口
     * @type {!Object}
     */
    const parameters = {
      decimals: ModelValueUint8.create().setValue(0),
      viewDecimal,
      viewMethod,
    }
    const minAmount = ModelValueEther.create(parameters).setValue(TOKEN_MIN_AMOUNT_ETHER)

    if (dotenvAddressName) {
      address = getDotenvAddress(dotenvAddressName)
    }

    // TODO: 要让 address Model

    const result = reactive({
      // 有效的 token
      // TODO: 加更多判定
      isValidated: !!address,

      /**
       * 链式方法扩展
       * - this 指为根
       * @param {Function} callback(this)
       * @return {!Object}
       */
      extend(callback) {
        callback.apply(this, [this])

        return this
      },

      parameters,
      // TODO: 待考虑取消
      ...parameters,

      /**
       * Base
       */
      /** @type {string} */
      code,
      /** @type {string} */
      address,
      /** @type {Array} */
      abi,
      /** @type {Object}} */
      name: ModelValueString.create(),
      /** @type {Object} */
      symbol: ModelValueString.create(),
      /**
       * 标识
       * @type {string}
       */
      icon: icon || code,
      /**
       * 当前 Model 标识
       * @type {boolean}
       */
      isToken: true,

      /**
       * 获取该币种的 url
       * @type {string}
       */
      acquisitionUrl,

      /** @type {Object} */
      get contract() {
        const { isValidated, abi, address } = this

        // TODO: 待优化
        if (isValidated) {
          // // storeWallet.web3 在钱包失效、链接时已经变更了目标值
          // if (storeWallet.isValidated) {
          //   if (!__store__.isContractWallet) {
          //     console.log('select wallet web3')
          //     __store__.contract = new storeWallet.web3.eth.Contract(abi, address)
          //     __store__.isCcontractBase = false
          //     __store__.isContractWallet = true
          //   }
          // } else {
          //   if (!__store__.isCcontractBase) {
          console.log('select default web3')
          __store__.contract = new storeWallet.web3.eth.Contract(abi, address)
          // 可能会换钱包，因此重置
          __store__.isContractWallet = false
          __store__.isCcontractBase = true
          //   }
          // }
        }

        return __store__.contract
      },

      /** @type {string} */
      totalSupply: ModelValueEther.create(parameters),

      /**
       * 初始化列队
       */
      initiateSeries() {
        const { decimals } = parameters
        const { isValidated, address, contract, name, symbol, totalSupply, baseURI, maxSupply } = this

        if (!isValidated) return false

        swaps.multicall.series([
          { call: [address, contract.methods[baseURIMethodName]().encodeABI()], target: baseURI },
          { call: [address, contract.methods[nameMethodName]().encodeABI()], target: name },
          { call: [address, contract.methods[symbolMethodName]().encodeABI()], target: symbol },
          { call: [address, contract.methods[maxSupplyMethodName]().encodeABI()], target: maxSupply },
          { call: [address, contract.methods[totalSupplyMethodName]().encodeABI()], target: totalSupply },
        ])
      },

      baseURI: ModelValueString.create(),
      maxSupply: ModelValueEther.create({
        decimals: ModelValueUint8.create().setValue(0),
        viewDecimal: 0,
      }),

      // TODO: 设为通用方法
      /** @type {number} */
      get precision() {
        const { decimals } = this
        let result = __cache__.precision

        // 没变动则从缓存获取
        if (__cache__.decimals !== decimals.handled) {
          __cache__.decimals = decimals.handled
          result = __cache__.precision = Math.pow(10, decimals.handled)
        }

        return result
      },

      /**
       * 最小量
       * @type {Object}
       */
      minAmount,
      /**
       * 最大量
       * - 等同无限授权量
       * @type {Object}
       */
      maxAmount: ModelValueEther.create(parameters).setValue(TOKEN_MAX_AMOUNT_ETHER),
      /**
       * 无限授权量的的最小阈值
       * - 无限授权开启时，当已授权低于该值，将再次授权
       * @type {Object}
       */
      infiniteMinAmount: ModelValueEther.create(parameters).setValue(TOKEN_INFINITE_MIN_AMOUNT_ETHER),

      /**
       * 量值
       * - input 自带限制
       * TODO: 最终类型未确定
       * TODO: 目前使用 handled
       * @type {Object}
       */
      amount: ModelValueInput.create({
        ...parameters,
        minInput: minAmount,
      }),

      /**
       * 量值是否有效
       * TODO: 没用到，应该由 amount 自身带入
       * @type {boolean}
       */
      get isValidAmount() {
        const { minAmount, maxAmount, amount, error } = this
        const bnAmountEther = BN(amount.ether)
        let result = false

        // 符合数据类型
        // TODO: 可加打点 error
        if (!BN.isBigNumber(bnAmountEther)) return result

        // amount >= minAmount && maxAmount <= amount
        result = bnAmountEther.gte(minAmount.ether) && bnAmountEther.lte(maxAmount.ether)

        if (!result) {
          error.message = i18n.$i18n.global.t('message.valueOutValidRange')
        }

        return result
      },

      /**
       * 与其他 token 产生的关联数据
       * - TODO: ??? 由 isSufficientAmount() 更新
       * - ??? 只保存当前钱包地址的相关数据，当钱包切换、断开后重置
       * - ???? 与 toContractAddresses 产生关系
       * - token address: { At: 1231233, amount: 量值对象 }
       * @type {Object}
       */
      associatedTokens: {},
      /**
       * associatedTokens 数据集的单元 Model
       * @param {Object} _token
       * @return {Object}
       */
      associatedTokenModel(_token) {
        const __root__ = this
        // TODO: 待优化
        const parameters = {
          decimals: __root__.decimals,
          viewDecimal,
          viewMethod: __root__.decimals,
        }

        let _tokenParameters = {}
        // TODO: 待优化
        if (_token) {
          _tokenParameters = {
            decimals: _token.decimals,
            viewDecimal: _token.viewDecimal,
            viewMethod: _token.viewMethod,
          }
        }

        return {
          /**
           * 与 key 相同
           * @type {Object}
           */
          address: ModelValueAddress.create().setValue(_token.address),
          ...customAssociatedTokenModel(_token, __root__),

          /**
           * 已授权的量
           * - __root__.getAllowance -> associatedToken.allowance -> associatedToken.isNeedApprove -> associatedToken.isResetApprove
           * @type {Object}
           */
          allowance: ModelValueEther.create({
            ...parameters,
            // 更新授权量的间隔
            stateParams: { expire: 10 },
          }),
          /**
           * 是否需要授权
           * - true 需要授权时
           * @type {boolean}
           */
          get isNeedApprove() {
            const { isInfiniteAllowance, infiniteMinAmount, amount } = __root__
            const { allowance } = this
            const bnAllowanceEther = BN(allowance.ether)
            let result = false
            // allowance 是否已初始化
            if (!allowance.state.initialized) return result

            // 与已授权量比较
            result = bnAllowanceEther.lt(
              // 是否无限授权
              isInfiniteAllowance
                ? // 小于无限授权量最小阈值
                  infiniteMinAmount.ether
                : // 授权量不足当前量
                  amount.ether,
            )

            return result
          },
          /**
           * 重置授权
           * - 调用 isNeedApprove
           * - 同步需要授权的量
           * @type {boolean}
           */
          get isResetApprove() {
            const { isInfiniteAllowance, maxAmount, amount } = __root__
            const { isNeedApprove, allowance, approve } = this
            const bnAllowanceEther = BN(allowance.ether)
            // 需要授权，且当前授权量大于0
            const result = isNeedApprove && bnAllowanceEther.gt(0)

            // 同步 approve 需要授权的量
            if (result) {
              // 要修改成重置 0 完成后再自动无限授权，针对授权攻击
              approve.ether = '0'
            } else {
              // 是否无限授权
              approve.ether = isInfiniteAllowance ? maxAmount.ether : amount.ether
            }

            return result
          },
          /**
           * 申请授权量
           */
          approve: ModelValueEther.create(parameters),

          /**
           * 在 __root__ 中的余额
           * - _token
           * @type {Object}
           */
          balance: ModelValueEther.create(_tokenParameters),

          // TODO: 以下是针对 lpt 的
          // 挖矿奖励数量
          miningPendingRewards: ModelValueEther.create(_tokenParameters),
          // 待结算奖励数
          settleableReward: ModelValueEther.create(_tokenParameters),

          state: ModelState.create(),

          /**
           * 重置
           */
          // TODO: 待考虑，可能销毁再创建更完整
          reset() {
            const { state } = this
            state.reset()
          },
        }
      },
      /**
       * 获取 associatedTokens 数据集中的值
       * - 不存在则使用 associatedTokenModel 创建
       * @param {Object|string} _token
       * @return {!Object}
       */
      getAssociatedToken(_token) {
        const { associatedTokens } = this

        if (!_token) {
          // TODO: 如果 _token 不存在，而 create() 还依赖其结构，需要默认化
          _token = { address: '__UNDEFINED_ADDRESS__' }
        }

        const { address } = _token
        return (
          associatedTokens[address] ||
          // 创建
          (associatedTokens[address] = this.associatedTokenModel(_token))
        )
      },
      /**
       * 关联合约的地址集
       * @type {Array}
       */
      toContractAddresses: [],

      /**
       * 是否无限授权数
       * - 当前 token
       * @type {boolean}
       */
      isInfiniteAllowance,
      // /**
      //  * 是否需要重置授权
      //  * @type {boolean}
      //  */
      // needResetAllowance: false,

      /**
       * s
       */
      // needApproval () {

      // },

      /**
       * 授权量是否足够
       * - 监听 amount 变动
       * @param {string} toContractAddress
       * @return {boolean}
       */
      // async isSufficientAmount (toContractAddress) {
      //   return result
      // },

      /**
       * 获取授权量
       * - 自动关联钱包
       * - 自动关联 associatedTokens
       * @param {string} toAddress 目标地址
       * @return {Promise}
       */
      async getAllowance(toAddress) {
        const { contract } = this
        let result = '0'

        // 必须有授权到的目标地址、钱包数据可用
        if (!(toAddress && storeWallet.isValidated)) return result

        const { allowance } = this.getAssociatedToken({ address: toAddress })

        // allowance 没有获取过、数据到期
        if (!allowance.state.initialized || allowance.state.isExpired) {
          allowance.state.beforeUpdate()
          // 更新
          // TODO: 考虑如何 multi
          // TODO: 可以考虑把该地址得信息都获得，并存起来
          allowance.ether = await contract.methods[allowanceMethodName](storeWallet.address.handled, toAddress).call()
        }

        result = allowance.ether

        return result
      },

      /**
       * 触发授权
       * - 自带授权量更新、是否需要授权的校验
       * @param {string} toContractAddress
       * @param {boolean=} forcedReset 无视条件强制重置
       * @return {Promise} { successful: false }
       */
      async approve(toContractAddress, forcedReset = false) {
        const { contract, error, state } = this

        // 必须钱包数据可用
        if (!storeWallet.isValidated) return false
        // 校验作用
        await this.getAllowance(toContractAddress)
        // getAllowance 处理完数据后
        const associatedToken = this.getAssociatedToken({ address: toContractAddress })

        const isNeedApprove = forcedReset || associatedToken.isNeedApprove
        // 是否需要授权
        if (!isNeedApprove) return false
        // TODO: 这里要能拿到 toContractAddress 的 code
        const { update, dismiss } = notify.notification({
          message:
            forcedReset || associatedToken.isResetApprove
              ? i18n.$i18n.global.t('global.msg.resettingApprove')
              : i18n.$i18n.global.t('global.msg.approving'),
        })

        const approveEther = forcedReset ? '0' : associatedToken.approve.ether

        associatedToken.state.beforeUpdate()
        state.beforeUpdate()

        // TODO: 考虑避免一个token 同时多次提交
        return new Promise((resolve, reject) => {
          const walletAddress = storeWallet.address.handled

          try {
            contract.methods[approveMethodName](toContractAddress, approveEther)
              .send({
                from: walletAddress,
                // TODO:
                // gasPrice: ,
                // gas: ,
              })
              .once('transactionHash', transactionHash => {
                // TODO: 自定义该提示（支持 i18n）
                notify.handler(transactionHash)

                listenEvent({
                  name: 'Approval',
                  contract,
                  transactionHash,
                })
                  .then(data => {
                    /* data
                    {
                      returnValues: {
                        owner: '0x'
                        spender: '0x'
                        value: '0'
                      }
                    }
                  */
                    const { returnValues } = data
                    const filter =
                      returnValues.owner === walletAddress &&
                      returnValues.spender === toContractAddress &&
                      returnValues.value === approveEther

                    if (!filter) return false

                    dismiss() // 销毁
                    associatedToken.allowance.ether = approveEther
                    associatedToken.state.afterUpdate()
                  })
                  .catch(err => {
                    // TODO: 增加提示内容的变更
                    dismiss() // 销毁
                    associatedToken.allowance.ether = approveEther
                    associatedToken.state.afterUpdate()
                  })
                  .finally(() => {})
              })
              .on('error', error.handler)
              .catch(err => {
                notify.updateError({
                  update,
                  code: err.code,
                  message: err.message,
                })

                associatedToken.state.afterUpdate()
                state.afterUpdate()

                reject({
                  successful: false,
                })
              })
          } catch (err) {
            notify.updateError({
              update,
              code: err.code,
              message: err.message,
            })

            state.afterUpdate()
          }
        }).catch(err => {
          // TODO:
          console.error(err)
          state.afterUpdate()
        })
      },

      /**
       * Wallet
       */
      walletBalanceOf: ModelValueWallet.create({
        ...parameters,
        async trigger() {
          swaps.multicall.series([
            {
              call: [address, __store__.contract.methods[balanceOfMethodName](this.address).encodeABI()],
              target: this,
              result,
            },
          ])
        },
      }),

      /**
       * @param {string} address
       * @return {string}
       */
      async getBalanceOf(address) {
        const { contract } = this
        const result = await contract.methods[balanceOfMethodName](address).call()

        return result
      },

      state: ModelState.create(stateParams),
      error: ModelValueError.create(),
    })

    // TODO: temp
    // TODO: 是否还有很好的方案
    result.initiateSeries()

    return result
  },
}
