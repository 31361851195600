/** @format */

import ModelState from '../base/state'

export default {
  /**
   * @param {Object} opts
   * @param {string|number} opts.value 预设值
   * @param {Function=} opts.referrer 引用器
   * @return {!Object}
   */
  create({ value = undefined, referrer = () => new Promise((resolve, reject) => {}) } = {}) {
    const __default__ = {
      handled: '',
      view: '-',
    }
    const __store__ = {
      handled: __default__.handled,
    }

    const result = {
      type: 'string',

      /**
       * 引用器
       * - 调用后会检查条件，再改写自身
       * @type {Function}
       */
      referrer,

      /**
       * IO
       * @type {string|number}
       */
      get value() {
        return this.handled
      },
      set value(val) {
        this.handled = val
      },

      /** @type {string} */
      get handled() {
        return __store__.handled
      },
      set handled(val) {
        const { state } = this

        __store__.handled = val + ''
        state.afterUpdate()
      },

      /** @type {string} */
      get view() {
        const { handled, state } = this

        return state.updated ? handled : __default__.view
      },

      state: ModelState.create(),
    }

    // 预设
    value != undefined && (result.value = value)

    return result
  },
}
