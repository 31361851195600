<template>
  <!-- <a-layout-header ref="header" class="container-fluid px-0 d-flex flex-column justify-content-center align-items-center"> -->
    <!-- <a-layout-content class="container-fluid px-0 d-none d-md-flex">
      <div class="latest-banner container-fluid d-flex flex-wrap align-items-center justify-content-center">
        <span class="p-1 fw-bold">Be the FIRST to know NFT <span class="mark">PRO</span> ticket launch</span>
        <a-button size="small" type="secondary" class="d-flex align-items-center ms-2">
          <a class="d-flex align-items-center fw-bold" href="https://discord.com/invite/twitterscan" target="_blank">
            <iDiscord class="me-2" />Join us on Discord
          </a>
        </a-button>
      </div>
    </a-layout-content> -->

    <!-- <div class="latest-banner container-fluid p-1" v-show="ixd.latest">
      <span v-html=ixd.latest></span>
      <a class="px-2" @click="onLatest" href="javascript:void(0);">{{ $t('global.base.more') }}</a>
    </div> -->
    <div ref="headerContent" class="header-content container-lg px-4 px-md-5 d-flex align-items-center">
      <router-link to="/" class="d-flex pe-2" title="MetaScan">
        <iLogoAll class="me-3" />
      </router-link>

      <div class="d-flex align-items-center ms-auto" >
        <a-menu
          v-model:selectedKeys="ixd.currentViewName"
          mode="vertical-right"
          class="d-flex col d-none d-lg-flex navSide me-4">
          <a-menu-item v-for="item in structure.navs"
            :key="item.id"
            >
            <template v-if=item.to>
              <router-link :to=item.to :title="$t(item.i18n)">{{ $t(item.i18n) }}</router-link>
            </template>
            <template v-else>
              <a :href=item.href target="_blank" :alt="$t(item.i18n)">{{ $t(item.i18n) }}</a>
            </template>
          </a-menu-item>
        </a-menu>

        <a-button @click="onWalletMenuClick" class="d-flex align-items-center px-3" size="small">
          <template v-if=ixd.wallet.isValidated>
            {{ ixd.wallet.addressShortened }}
          </template>
          <template v-else>
            {{ $t('layer.header.wallet.connect') }}
          </template>
        </a-button>

        <a-modal
          v-model:visible="walletAccountInfoVisible"
          :title="$t('global.base.account')"
          centered
          class="walletAccountInfo"
          okText=''
          maskClosable
          cancelText=''
          :footer="null"
        >
          <template #closeIcon>
            <iClose class="ms-auto mt-2" />
          </template>
          <span class="d-flex justify-content-between align-items-center">
            <small>{{ $t('global.base.connectedWallet', [ixd.wallet.name] ) }}</small>
            <a-button @click="onWalletMenuClick" size="small" type="link">{{ $t('layer.header.wallet.change') }}</a-button>
          </span>
          <span class="fs-4">{{ ixd.wallet.addressShortened }}</span>
          <small class="d-flex align-items-center pt-2">
            <a href="javascript:void(0);" :data-clipboard-text=ixd.wallet.address.handled class="copyAddress d-flex align-items-center">
              <span class="h4 icon-copy me-1"></span>
              {{ $t(copied
                ? 'layer.header.wallet.copiedAddress'
                : 'layer.header.wallet.copyAddress') }}
            </a>
            <a :href=ixd.wallet.getEtherscanUrl target="_blank" class="d-flex align-items-center">
              <span class="h4 icon-maximize me-1 ms-3"></span>
              {{ $t('layer.header.wallet.viewOnEtherscan') }}
            </a>
          </small>
        </a-modal>
      </div>

      <div class="d-flex ms-4 d-lg-none py-1 pointer" @click="showDrawer">
        <iMenu />
      </div>
    </div>
  <!-- </a-layout-header> -->
  <!-- <div ref="headerHolder" class="header-holder"></div> -->

  <a-drawer
    :closable="false"
    width="100%"
    placement="right"
    v-model:visible="menuVisible"
  >
    <div class="d-flex justify-content-between align-items-center">
      <iLogoAll />
      <div class="d-flex p-2 pointer" @click="onMenuClose"><iClose class="ms-auto" /></div>
    </div>
    <a-menu
      v-model:selectedKeys="ixd.currentViewName"
      mode="vertical-right"
      class="mt-4 navSide">
      <a-menu-item class="ps-0 my-3" v-for="item in structure.navs"
        :key="item.id"
        >
        <template v-if=item.to>
          <router-link :to=item.to>{{ $t(item.i18n) }}</router-link>
        </template>
        <template v-else>
          <a :href=item.href target="_blank">{{ $t(item.i18n) }}</a>
        </template>
      </a-menu-item>
    </a-menu>
  </a-drawer>
</template>

<script>
import { h } from 'vue'
import { Button, Layout, Menu, Drawer, Modal } from 'ant-design-vue'
import { iLogoAll, iMenu, iClose, iDiscord } from '../../components/icons'
import { parseAntComponent } from '../../utils/helpers'
import ClipboardJS from 'clipboard'

export default {
  components: {
    ...parseAntComponent([Button, Layout.Header, Drawer, Menu, Menu.Item, Modal]),
    iLogoAll,
    iMenu,
    iClose,
    iDiscord
  },
  data() {
    return {
      menuVisible: false,
      walletAccountInfoVisible: false,
      copied: false,
    };
  },
  async mounted () {
    this.copyAddress()
  },
  methods: {
    showDrawer() {
      this.menuVisible = true;
    },
    copyAddress () {
      const { wallet } = this.$store
      // TODO:
      let clipboard = new ClipboardJS('.copyAddress');

      clipboard.on('success', e => {
        this.copied = true
        setTimeout(() => {
          this.copied = false
        } ,750)
      });

      // clipboard.on('error', function(e) {
      //     console.log(e);
      // });
    },
    onWalletMenuClick (val) {
      const { wallet } = this.$store
      const walletAccountInfoVisible = this.walletAccountInfoVisible

      if (wallet.isValidated) {
        walletAccountInfoVisible
          && wallet.changeWallet()

        this.walletAccountInfoVisible = !walletAccountInfoVisible
      } else {
        wallet.changeWallet()
      }
    },
    onLatest () {
      const { $router, $store } = this
      const { i18n: { $i18n, locale }, announcements: { latest } } = $store

      const modal = Modal.confirm({
        title: h('div', {
          class: ['fs-5', 'pb-3'],
          innerHTML: latest[locale].title,
        }),
        width: 720,
        forceRender: true,
        content: h('p', {
          class: ['fs-6'],
          innerHTML: latest[locale].content,
        }),
        centered: true,
        mask: false,
        okText: $i18n.global.t('layer.header.latest.close'),
        cancelText: $i18n.global.t('layer.header.latest.more'),
        onCancel (close) {
          $router.push({ name: 'Announcement', path: '/announcement' })
          close()
        }
      })
    },

    onMenuClose() {
      this.menuVisible = false
    }
  },
  computed: {
    structure () {
      return {
        navs: [
          { id: 'Home', to: '/', i18n: 'layer.header.nav.home' },
          { id: 'Registry3', href: 'https://registry3.com/', i18n: 'layer.header.nav.registry3' },
          { id: 'NNS', href: 'https://nns.network/', i18n: 'layer.header.nav.nns' },
          { id: 'TwitterScan', href: 'https://twitterscan.com', i18n: 'layer.header.nav.twitterScan' },
          // { id: 'NFT', to: '/about', i18n: 'layer.header.nav.about' },
        ]
      }
    },

    ixd () {
      const { $route } = this
      const { wallet, announcements: { latest }, i18n: { locale } } = this.$store

      return {
        currentViewName: [$route.name],
        wallet,
        latest: latest
          && latest[locale]
          && latest[locale].title
      }
    }
  }
}
</script>

<style lang="less">
.mark {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #0E1014;
  background: #FFC933;
  border: 1px solid #0E1014;
  border-radius: 3px;
  padding: 0px 3px;
}

.ant-layout-header {
  height: auto !important;
  position: fixed;
  z-index: 10;
  backdrop-filter: blur(60px);
  // background-color: rgba(255, 255, 255, 0.6);
}
.latest-banner {
    background: linear-gradient(135deg, #99DDFF 0%, #66CCFF 100%);
    color: #0E1014;
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .header-content {
    padding-top: 20px;
    padding-bottom: 20px;
  }

.header-holder {
  height: 80px;
}

.navSide {
  &.ant-menu {
    background-color: transparent;
    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;
    }
    .ant-menu-item-selected {
      font-family: 'Montserrat-Bold';
      border-width: 0;
    }
    .ant-menu-item {
      width: auto;
    }
  }
  &.ant-menu-horizontal {
    border-bottom: 0px;
    .ant-menu-item, .ant-menu-item:hover {
      border-bottom: 0px;
    }
  }
  &.ant-menu-vertical-right {
    border: 0;
  }
  .ant-menu-inline {
    border-right: 0px;
    .ant-menu-item {
      border-right: 0px;
    }
  }
}

.walletAccountInfo {
  .ant-modal-content {
    border-radius: 8px !important;
    padding-bottom: 20px;
    .ant-modal-header {
      padding: 20px 24px;
      border-radius: 8px 8px 0 0;
      border-bottom: 0;
      .ant-modal-title {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        color: rgba(17, 20, 20, 0.9);
      }
    }
    .ant-modal-body {
      padding: 8px 8px 8px 16px;
      border: 1px solid rgba(17, 20, 20, 0.1);
      border-radius: 4px;
      margin: 0 24px;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>