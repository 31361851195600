<template>

  <a-layout-content class="container-fluid p-0 py-5">
    <a-layout-content class="container-lg d-flex flex-column ps-4 ps-md-5 cover align-items-center my-5">
      <div class="col-12 col-md-6 col-lg-8">
        <img class="w-100p" src="../assets/base/home/cover@2x.png" />
      </div>
      <div class="d-flex flex-column col-12">
        <span class="h1 fw-bold py-4 mt-4 text-center">
          Coming Soon ...
        </span>
      </div>
    </a-layout-content>
  </a-layout-content>

</template>

<script>
import { Layout, Button, Popover, Tabs, Input, message } from 'ant-design-vue'
import Busy from '../components/busy'
import ButtonBusy from '../components/button-busy'
import { parseAntComponent } from '../utils/helpers'

export default {
  components: {
    ...parseAntComponent([Layout.Content, Button, Popover, Tabs, Tabs.TabPane, Input]),
    Busy,
    ButtonBusy,
  },
  data() {
    return {
      currStep: null
    }
  },
  async mounted () {
  },
  methods: {

  },
  computed: {
    // 结构性
    structure() {

      return {
      }
    },

    ixd() {
      const { nftSwaps } = this.$store

      return {
        rocketNFT: nftSwaps.rocketNFT
      }
    }
  },
}
</script>

<style scoped lang="less">
.cover {
  height: 70vh;
  justify-content: center;
}

@media (min-width: 2000px) {

}
@media (max-width: 992px) {

}
@media (max-width: 768px) {

}

@media (max-width: 540px) {
  .h1 {
    font-size: 40px;
    line-height: 48px;
  }
  .h2 {
    font-size: 26px;
    line-height: 30px;
  }
}
</style>
